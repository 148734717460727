import {ref} from 'vue';
import {useAppState} from '../Stores/AppState';

// Default value for snackbar
const snackbar = ref({
    title: '',
    message: 'Lorem ipsum dolor sit amet consectetur!',
    visible: false,
    timeout: 3000,
    color: 'success',
    location: 'bottom',
    avatar_url: null
});

const activePanel = ref(1);

const chatPopover = ref(false);

const activeChatData = ref({
    visible: false,
    data: {},
});

const activeEditorData = ref({
    visible: false,
    data: {},
});

const selectedPanelItem = ref(null);

const activity_details = ref()

const snackbar_clicked_outside = ref(false)

export default function utilities() {

    const app_state_store = useAppState()

    function setActivePanel(panel) {
        activePanel.value = panel;
    }

    function setChatData(obj) {
        activeChatData.value.visible = obj?.visible || activeChatData.value.visible;
        activeChatData.value.data = obj?.data || activeChatData.value.data;
    }

    function toggleChat() {
        if (activeChatData.value.visible) {
            activeChatData.value.data = {};
        }
        activeChatData.value.visible = !activeChatData.value.visible;
    }

    function isEditorOpen() {
        return activeEditorData.value.visible
    }

    function toggleChatOff() {
        activeEditorData.value.visible = false;
    }

    function setTextEditor(obj) {
        activeEditorData.value.visible = obj?.visible || activeEditorData.value.visible;
        activeEditorData.value.data = obj?.data || activeEditorData.value.data;
    }

    function toggleTextEditor() {
        if (activeEditorData.value.visible) {
            activeEditorData.value.data = {};
        }
        activeEditorData.value.visible = !activeEditorData.value.visible;
    }

    function toggleTextEditorOff() {
        activeEditorData.value.visible = false;
    }

    function setSelectedPanelItem(obj) {
        selectedPanelItem.value = obj;
        activeChatData.value.data = obj;
    }

    function toggleChatPopover(value = null, close_details = true) {
        chatPopover.value = value === null ? !chatPopover.value : value;
        activeChatData.value.visible = value === null ? !chatPopover.value : value;
        app_state_store.updateConversationState(chatPopover.value)

        if (!value || close_details) {
            // app_state_store.toggleActivityDetails(false)
        }
    }

    function setSnackbar(obj) {
        snackbar.value.title = obj?.title || '';
        snackbar.value.message = obj?.message || '';
        snackbar.value.visible = obj?.visible || snackbar.value.visible;
        snackbar.value.timeout = obj?.timeout || snackbar.value.timeout;
        snackbar.value.color = obj?.color || snackbar.value.color;
        snackbar.value.location = obj?.location || snackbar.value.location;
        snackbar.value.avatar_url = obj?.avatar_url || null;
        snackbar.value.icon = obj?.icon || snackbar.value.icon;
        snackbar.value.class = obj?.class || snackbar.value.class;
    }

    function toggleSnackBarOff() {
        snackbar.value.visible = false;
    }

    function toggleSnackBarOn() {
        snackbar.value.visible = true;
    }

    function toggleSnackBar(obj) {
        snackbar.value.visible = false

        let to = setTimeout(() => {
            snackbar.value.title = obj?.title || '';
            snackbar.value.message = obj?.message || ''
            snackbar.value.timeout = obj?.timeout || snackbar.value.timeout
            snackbar.value.color = obj?.color || snackbar.value.color
            snackbar.value.location = obj?.location || snackbar.value.location
            snackbar.value.avatar_url = obj?.avatar_url;
            snackbar.value.visible = true
            snackbar.value.icon = obj?.icon || snackbar.value.icon
            snackbar.value.class = obj?.class || snackbar.value.class
            clearTimeout(to)
        }, 50)
    }

    function showSnackbar(message, type, avatar_url = null) {
        toggleSnackBarOff();
        setSnackbar({
            message: message,
            visible: false,
            timeout: 3000,
            color: type,
            location: 'bottom right',
            avatar_url: avatar_url
        });
        toggleSnackBarOn();
    }

    function showSuccessSnackbar(message, avatar_url = null, type = 'success') {
        showSnackbar(message, type, avatar_url);
    }

    function showErrorSnackbar(message, avatar_url = null, type = 'error') {
        showSnackbar(message, type, avatar_url);
    }

    function showInfoSnackbar(message, avatar_url = null, type = 'info') {
        showSnackbar(message, type, avatar_url);
    }

    function copyToClipboard(message) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(message);
            return;
        }
        const body = document.querySelector('body');
        const area = document.createElement('textarea');
        area.style.height = '0';
        body.appendChild(area);

        area.value = message
        area.select();
        document.execCommand('copy');
        body.removeChild(area);
    }

    function convertMinutesToHoursAndMinutes(total_minutes) {
        const hours = Math.floor(total_minutes / 60);
        const minutes = total_minutes % 60;
        return {hours, minutes};
    }

    function calculateDayProgress(total_minutes) {
        const minutesInADay = 8 * 60;
        return (total_minutes / minutesInADay) * 100;
    }

    function setSnackbarClickedOutside() {
        snackbar_clicked_outside.value = true
    }

    function getFileNameFromResponse(response) {
        if (response.headers.hasOwnProperty('content-disposition')) {
            const content_disposition = response.headers['content-disposition'];
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(content_disposition);
            if (matches && matches.length > 1) {
                return  matches[1].replace(/['"]/g, '');
            }
        }
        return null
    }

    function downloadFileFromResponse(response, name = null){

        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        })

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = name || getFileNameFromResponse(response)
        link.click()
    }

    return {
        // States
        snackbar,
        chatPopover,
        selectedPanelItem,
        activeEditorData,
        activePanel,
        activeChatData,
        snackbar_clicked_outside,

        // Functions
        toggleChatPopover,
        setSnackbar,
        toggleSnackBarOff,
        toggleSnackBarOn,
        toggleSnackBar,
        showSuccessSnackbar,
        showInfoSnackbar,
        showErrorSnackbar,
        setSelectedPanelItem,
        setTextEditor,
        toggleTextEditor,
        toggleTextEditorOff,
        setActivePanel,
        toggleChat,
        setChatData,
        toggleChatOff,
        isEditorOpen,
        copyToClipboard,
        convertMinutesToHoursAndMinutes,
        calculateDayProgress,
        setSnackbarClickedOutside,
        getFileNameFromResponse,
        downloadFileFromResponse
    };
}
