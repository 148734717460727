import axios from "axios"
import { ref, computed } from "vue"
import Cache from "../Utilities/Cache";
const cache = new Cache;
import conversationsApi from './conversations';
const { setContextualConversation,
    setConversationWithIdAsCurrent,
    setConversationForItemAsCurrent,
    updateConversationNameWith,
    updateFakeConversationNameWith,
    removeFakeConversation,
    removeConversationById,
    openConversationOnly,
    /* setCurrentConversationById,
    setFakeCurrentConversation */
} = conversationsApi()
import BoardsApi from './boards';
const { currentActiveBoard,
        getListById,
        removeListById,
        getItemById,
        getBoardAndItemByItemId,
        openBoard,
        removeItemById,
        addListToBoardAndUpdateMap,
        addItemToListAndUpdateMap,
        prepareMapForItemIdBoardAndList,
        workspaceId
    } = BoardsApi();
import { setSidePanelCurrentViewWithEditor, closeSidePanel, setSidePanelCurrentViewWithItems } from './helpers';
import LoggedInUser from "./logged-in-user";
const { setUserContextualListItemId, saveCurrentState } = LoggedInUser()
const list = ref([])
const listLoading = ref(false)
const listItemForContextualChat = ref({})
const listItemOpenedOnlyForEditor = ref(null);

const getListIndexAndListItemForItemId = (itemIdToFind) => {

    if (!itemIdToFind)
        return { listIndex: -1, listItem: null };

    let listItem;

    const listIndex = list.value
        ?.findIndex((_list, _listIndex) => {
            return listItem = _list.items?.find((_listItem) => _listItem.list_item_id == itemIdToFind)
        })
    return { listIndex, listItem }
}

const getListIndexById = (list_id) => {
    return list.value.findIndex(_list => _list.list_id == list_id)
}

const getConversationIndexInListItemById = (listItem, conversation_id) => {
    if (!conversation_id || !listItem?.conversations?.length)
        return -1;
    return listItem?.conversations?.findIndex(conv => conv.conversation_id == conversation_id)
}

function doesListItemHaveConversation(listItem, conversation_id) {
    return getConversationIndexInListItemById(listItem, conversation_id) == -1 ? false : true;
}

function doesConversationByIdBelongToListItem(conversation_id, listItem) {
    return getConversationIndexInListItemById(listItem, conversation_id) == -1 ? false : true;
}

function doesListItemHaveAnyConversations(listItem) {
    return listItem?.conversations?.length ? true : false;
}

const findListItemByIdInList = (_list, itemId) => {
    return _list.items.find(item => item.list_item_id == itemId)
}

const getItemIndexInList = (_list, searchItem) => {
    return _list.items.findIndex(item => item.list_item_id == searchItem.list_item_id)
}

const getItemIndexInListById = (_list, searchItemId) => {
    return _list.items.findIndex(item => item.list_item_id == searchItemId)
}

function updateNewListIndex() {
    const activeBoard= currentActiveBoard();
    let newListIndex = 0;
    activeBoard
        ?.list
        ?.forEach(list => {
            if (list.list_name == 'New List')
                list.new_list_index = ++newListIndex;
        })
}
function  fakeListIndex(tmpList) {
    if( !tmpList )
        return -1;
    return tmpList.findIndex( _list => _list.is_fake)
}
function appendFakeList(tmpList) {
    if( !tmpList )
        tmpList = list.value
    const fakeIndex = fakeListIndex(tmpList)
    if( fakeIndex >= 0 )
        return;
    list.value.push({ is_fake: true, list_name: "Add New List" })
}

function setList(listNew) {
    list.value = listNew
    appendFakeList();
    updateNewListIndex();
}

function startLoading() {
    listLoading.value = true
}

function isListNotLoaded() {
    return !list.value || !list.value.length
}

function updateLoading() {
    listLoading.value = false
}
function prepareUrl(list_id = null, item_id = null, suffix = "") {
    const activeBoard = currentActiveBoard();
    if (!activeBoard?.board_id)
        return null;
    let url = `/boards/${activeBoard.board_id}`;

    list_id && (url += `/list/${list_id}`);
    item_id && (url += `/items/${item_id}`)
    url && (url += `${suffix}`);
    console.log("")
    return `${url}?workspace=${workspaceId.value}`;
}

async function fetchList() {
    setList([]);
    const activeBoard = currentActiveBoard();
    setList( activeBoard?.list);

    return;
    const url = prepareUrl(null, null, "/list");
    if (!url)
        return console.log("Select Board first");
    startLoading();
    //if (isListNotLoaded()) {
    //console.log("fetching--list");
    const { data: body } = await axios.get(url);
    setList(body.data);
    //}
    updateLoading();
}

//const list = computed(() => list.value);
//const listLoading = computed(() => listLoading.value);
//const getListItem = computed( () => state.value.list);

async function createListOld(list_name) {
    const url = prepareUrl(null, null, "/list");
    if (!url)
        return console.log("Select Board first");

    const data = { list_name }
    const { data: listNew } = await axios.post(url, data);
    if (listNew && !listNew.items)
        listNew.items = []
    list.value.splice(list.value.length - 1, 0, listNew);
    return listNew;
}

function editList(index) {
    list.value.forEach(listDetail => {
        delete listDetail.isEdit;
        delete listDetail.newName;
    })
    list.value[index].isEdit = true
    list.value[index].newName = list.value[index].list_name;
}

async function updateListOld(index) {
    const listDetail = list.value[index]
    listDetail.newName = listDetail.newName?.trim()
    // Sent request to server if name not changed
    if (!listDetail.newName || listDetail.list_name == listDetail.newName)
        return cancelEditList(index)

    const data = { list_id: listDetail.list_id, list_name: listDetail.newName };
    const url = prepareUrl(data.list_id);
    const { data: listUpdated } = await axios.post(url, data);
    listUpdated.items = list.value[index].items;
    list.value[index] = listUpdated
    updateNewListIndex();
    cancelEditList(index)
}

async function updateListSequence() {
    let url = prepareUrl(null, null, "/list-shuffle"); // `/boards/{board_id}/list-shuffle`
    if (!url)
        return console.log("Select Board first");

    let newListSequnece = [];
    let newSequence = 1;
    list.value.forEach(_list => {
        _list.list_sequence_id = newSequence++;
        newListSequnece.push({
            list_id: _list.list_id,
            list_sequence_id: _list.list_sequence_id
        });
    })
    await axios.post(url, { "new_list_sequence": newListSequnece });
}

async function archiveListOld(index) {
    const listDetail = list.value[index]
    const url = prepareUrl(listDetail.list_id, null, "/archive") //`/boards/1/list/${data.list_id}/archive`
    const data = { list_id: listDetail.list_id };
    const { data: listArchived } = await axios.post(url, data);
    list.value.splice(index, 1)
    updateNewListIndex();
}

async function deleteList(index) {
    const listDetail = list.value[index];
    const data = { list_id: listDetail.list_id };
    const url = prepareUrl(listDetail.list_id, null, "/delete"); //`/boards/1/list/${data.list_id}/delete`
    const { data: listDeleted } = await axios.post(url, data);
    const archivedList = list.value.splice(index, 1)[0];
    updateNewListIndex();
    deleteAllItemsForList(archivedList);
}

function deleteAllItemsForList(_list) {
    _list?.items
        ?.forEach((listItem) => {
            removeContextualConversationIfPresentForItem(listItem)
        })
}

function cancelEditList(index) {
    delete list.value[index].isEdit
    delete list.value[index].newName;
}
async function createNewListAndAddItem(sourceIndex, item) {
    const sourceList = list.value[sourceIndex];
    const itemIndex = getItemIndexInList(sourceList, item);
    if (itemIndex == -1)
        return alert("Not a Valid item")
    await createList('New List');
    sourceList.items.splice(itemIndex, 1);
    const newListIndex = list.value.length - 2; // last one always Fake list as to handle vuedraggable
    const destList = list.value[newListIndex];
    destList.items.push(item);
    updateNewListIndex();
    shuffleListItemsForList(newListIndex);
}

async function createList(newList) {
    if( !newList?.new_name )
        return;
    const url = prepareUrl(null, null, "/list");
    const activeBoard = currentActiveBoard();
    const data = { list_name: newList.new_name }
    const { data: listNew } = await axios.post(url, data);
    if (listNew && !listNew.items)
        listNew.items = []

    const boardList = activeBoard.list;
    let fakeIndex = fakeListIndex(boardList);
    if(fakeIndex == -1)
        fakeIndex = 0;
    boardList.splice(fakeIndex, 1, listNew);
    shuffleActiveBoardList();
    updateNewListIndex();
    appendFakeList(boardList);
}

async function updateList(listToUpdate) {
    if( !listToUpdate?.new_name )
        return;
    const url = prepareUrl(listToUpdate.list_id);
    const { data: listUpdated } = await axios.patch(url, { list_name: listToUpdate.new_name });
    listToUpdate.list_name= listUpdated.list_name;
    updateNewListIndex();
}

async function archiveList(listToArchive) {
    const url = prepareUrl(listToArchive.list_id, null, "/archive");
    const { data: listUpdated } = await axios.post(url);
    const activeBoard = currentActiveBoard();
    if( !activeBoard )
        return;
    let index = activeBoard.list?.findIndex( tmp => tmp.list_id == listToArchive.list_id);
    if(  index >= 0)
        activeBoard.list.splice(index, 1);
    updateNewListIndex();
}

async function  createItem(item, list) {
    let data = { list_id: list.list_id, list_item_name: item.new_name };
    const url = prepareUrl(data.list_id, null, "/items"); //`/boards/1/list/${data.list_id}/items`
    const { data: newItem } = await axios.post(url, data);

    let index = list.items.findIndex( item => item.is_fake )
    if( index == -1 )
        index = 0;
    list.items.splice(index, 1, newItem);
    shuffleItemsForList(list)
}

async function updateItemName(item, list) {
    const mapItem = getItemById(item.list_item_id);
    const list_id = list ? list.list_id : mapItem.list_id;
    let data = {list_item_name: item.new_name };
    const url = prepareUrl(list_id, item.list_item_id, "/name"); //`/boards/1/list/${data.list_id}/items`
    const { data: updatedItem } = await axios.patch(url, data);

    item.list_item_name = updatedItem.list_item_name;
    /* const index = list.items.findIndex(item => item.list_item_id == updatedItem.list_item_id)
    if (index == -1)
        return;
     */
    //list.items.splice(index, 1, updatedItem);
    const itemMapped = getItemById(item.list_item_id)
    if( itemMapped?.conversation_id  )
        updateConversationNameWith(itemMapped.conversation_id, item.list_item_name);
    /* contextual item & no conversation for this item */
    if(isItemContextual(item) && !itemMapped.conversation_id)
        updateFakeConversationNameWith(item.list_item_name)
}


async function getItemDescription(item) {
    const url = prepareUrl(item.list_id, item.list_item_id, '/description') //`/boards/1/list/${data.list_id}/items/${data.list_item_id}/description`

    try {
        const { data: itemData } = await axios.get(url);
        item.list_item_description = itemData.list_item_description;
        return itemData;
    }catch(err) {
        //alert("Error while fetching item description ")
        console.log("request failed to fetch item description");
    }
}

async function updateItemDescription(item) {
    const mapItem = getItemById(item.list_item_id);
    if( !mapItem )
        return console.log("Invalid item to update description");
    let data = {
        list_item_description: mapItem.list_item_description,
    };
    //console.log(mapItem, item , data, "updating descripton");
    const url = prepareUrl(mapItem.list_id, mapItem.list_item_id, '/description') //`/boards/1/list/${data.list_id}/items/${data.list_item_id}/description`
    await axios.patch(url, data);
}

async function archiveItem(itemToArchive, list) {
    const url = prepareUrl(list.list_id, itemToArchive.list_item_id, "/archive"); //`/boards/1/list/${data.list_id}/items`
    await axios.post(url);

    const index = list.items.findIndex(item => item.list_item_id == itemToArchive.list_item_id)
    if (index == -1)
        return;
    list.items.splice(index, 1);

    if( isItemOpenedForEditing(itemToArchive)) {
        resetEditorItem();
        setSidePanelCurrentViewWithItems()
    }

    if(!isItemContextual(itemToArchive))
        return;
    // clear contextual item & it's conversation from State
    resetContextualItem();
    const item = getItemById(itemToArchive.list_item_id);

    item?.conversation_id
        ? removeConversationById(item.conversation_id)
        : removeFakeConversation();
}


async function shuffleItemsForList(list) {
    //const itemIndex = _list.items.findIndex(item => item.list_item_id == itemId)
    let itemsWithUpdatedSequence = [];
    list.items.forEach((item, index) => itemsWithUpdatedSequence.push({ list_item_id: item.list_item_id, list_item_sequence_id: index + 1 }))
    let data = { list_id: list.list_id, list_items: itemsWithUpdatedSequence };
    const url = prepareUrl(data.list_id, null, "/shuffle") //`/boards/1/list/${data.target_list_id}/shuffle`
    await axios.post(url, data);
    prepareMapForItemIdBoardAndList();
}

async function deleteItem(itemToArchive, list) {
    const url = prepareUrl(list.list_id, itemToArchive.list_item_id); //`/boards/1/list/${data.list_id}/items`
    await axios.delete(url);

    const index = list.items.findIndex(item => item.list_item_id == itemToArchive.list_item_id)
    if (index == -1)
        return;
    list.items.splice(index, 1);
    //
}

function sync_addList(list) {
    addListToBoardAndUpdateMap(list)
}

function sync_updateList(list) {
    const currentList = getListById(list.list_id);
    if (!currentList)
        return console.log("no List found to update");

    currentList.list_name = list.list_name;
}

function sync_removeList(list) {
    const currentList = getListById(list.list_id)
    if (!currentList)
        return console.log("no List found to delete");

    const deleteAt = currentList.board.list.findIndex(tmpList => tmpList.list_id == list.list_id)
    if (deleteAt == null || deleteAt == -1)
        return console.log("List not found to remove inside list");
    currentList.board.list?.splice(deleteAt, 1);
    removeListById(list.list_id)
}

function sync_addItem(item) {
    addItemToListAndUpdateMap(item);
}

function sync_updateItem(item) {
    const currentItem = getItemById(item.list_item_id)
    if (!currentItem)
        return console.log("no item found to delete");

    currentItem.list_item_name = item.list_item_name;
    if (isItemOpenedForEditing(item))
        return console.log("is item opened for editing");
    currentItem.list_item_description = item.list_item_description;
}

function sync_removeItem(item) {
    const currentItem = getItemById(item.list_item_id)
    if (!currentItem)
        return console.log("no item found to delete");

    const deleteAt = currentItem.list.items.findIndex(tmpItem => tmpItem.list_item_id == item.list_item_id)
    if (deleteAt == null || deleteAt == -1)
        return console.log("Item not found to remove inside list");
    currentItem.list.items?.splice(deleteAt, 1);
    removeItemById(item.list_item_id);
}

async function moveItemFromListToListAt(item, list, destListIndex) {
    //delete item from list
    const deleteAt = list.items.findIndex(tmpItem => tmpItem.list_item_id == item.list_item_id)
    list.items.splice(deleteAt, 1);

    const activeBoard = currentActiveBoard();
    const destList = activeBoard.list[destListIndex];
    if (!destList.items?.length)
        destList.items = [];
    destList.items.push(item);
    shuffleItemsForList(destList);
}

async function moveItemFromListToNewList(item, list) {
    //delete item from list
    const deleteAt = list.items.findIndex(tmpItem => tmpItem.list_item_id == item.list_item_id)
    list.items.splice(deleteAt, 1);
    //create List with a name "New List"
    await createList({ new_name: 'New List'});

    // add item to this newly created list
    const activeBoard = currentActiveBoard();
    const newlyCreatedList = activeBoard.list[activeBoard.list.length - 2]; // as fake alredy added
    if( !newlyCreatedList.items?.length )
        newlyCreatedList.items = [];
    newlyCreatedList.items.push(item);

    updateNewListIndex();
    shuffleItemsForList(newlyCreatedList); // it updates list_id for this item
    shuffleActiveBoardList();
}

function isItemContextual(item) {
    return listItemForContextualChat.value?.list_item_id == item?.list_item_id;
}

function isItemOpenedForEditing(item) {
    return listItemOpenedOnlyForEditor.value?.list_item_id == item?.list_item_id;
}

function isContextualItemSet() {
    return listItemForContextualChat.value
}

function resetContextualItem() {
    //console.log(" resetContextualItem ");
    listItemForContextualChat.value
        && (listItemForContextualChat.value.is_contextual = false)

    listItemForContextualChat.value = {};
    //listItemOpenedOnlyForEditor.value = {}
    setUserContextualListItemId(null);
}

function resetEditorItem(params) {
    listItemOpenedOnlyForEditor.value = {}
}

function addListItem(index) {
    const newTask = { list_item_id: Date.now(), list_item_name: '', newName: '', isEdit: true, isNew: true }
    const listDetail = list.value[index]
    if (!listDetail.items)
        listDetail.items = [];
    listDetail.items.unshift(newTask)
    return newTask // this is to make sure, this input gets focus
}

function editListItem(listIndex, itemId) {
    const _list = list.value[listIndex];
    let item = _list.items.find(item => item.list_item_id == itemId)
    item.isNew = false
    item.isEdit = true;
    item.newName = item.list_item_name;
}

async function updateNameForListItem(listItem) {
    const url = prepareUrl(listItem.list_id, listItem.list_item_id, "/name")   //`/boards/1/list/${listItem.list_id}/items/${listItem.list_item_id}/name`
    if (!url)
        return console.log("Url not preapred");
    const { data: updatedItem } = await axios.post(url, { list_item_name: listItem.newName });
    listItem.list_item_name = updatedItem.list_item_name;

    updateListItemsConversationNameOpenedForEditing(listItem)
}

async function updateDescriptionForListItem(listItem) {
    const url = prepareUrl(listItem.list_id, listItem.list_item_id, "/description")   //`/boards/1/list/${listItem.list_id}/items/${listItem.list_item_id}/description`
    const { data: updatedItem } = await axios.post(url, { list_item_description: listItem.list_item_description });
    listItem.list_item_description = updatedItem.list_item_description;
}

/* cancelling editing is also handled */
async function saveListItem(listIndex, itemId, isCancel) {
    const _list = list.value[listIndex];
    const itemIndex = _list.items.findIndex(item => item.list_item_id == itemId)
    const item = _list.items[itemIndex]
    if (!item)
        return {};
    let newOrUpdatedlistItem;
    // if name not updated , then don't send any request to server

    if (!isCancel) {
        let data = { list_id: _list.list_id, list_item_name: item.newName, list_item_description: item.list_item_description };
        if (item.isNew) {
            const url = prepareUrl(data.list_id, null, "/items"); //`/boards/1/list/${data.list_id}/items`
            const { data: newItem } = await axios.post(url, data);
            newOrUpdatedlistItem = newItem;
            _list.items.splice(itemIndex, 1, newOrUpdatedlistItem)
            shuffleListItemsForList(listIndex);
        } else {
            data.list_item_id = item.list_item_id;
            data.list_item_description = item.list_item_description ?? '';
            const url = prepareUrl(data.list_id, data.list_item_id); //`/boards/1/list/${data.list_id}/items/${data.list_item_id}`
            const { data: updatedItem } = await axios.post(url, data);
            newOrUpdatedlistItem = updatedItem;
            _list.items.splice(itemIndex, 1, newOrUpdatedlistItem)
        }
    } else {
        if (item.isNew)
            _list?.items?.splice(itemIndex, 1);
    }

    updateListItemsConversationNameOpenedForEditing(item);

    delete item.isNew
    delete item.isEdit
    delete item.newName
    return newOrUpdatedlistItem
}

async function saveListItemAndUpdateListSequence(listIndex, listItem) {
    const _list = list.value[listIndex];
    const url = prepareUrl(_list.list_id, "/items"); //`/boards/1/list/${_list.list_id}/items`
    const { data: newItem } = await axios.post(url, listItem);
    _list.items.unshift(newItem)

    await shuffleListItemsForList(listIndex);
    const itemIndex = getItemIndexInList(_list, newItem);
    return itemIndex;
}

function updateListItemDescriptionOpenedForEditing(updatedItem) {
    const listItem = listItemOpenedOnlyForEditor.value ?? listItemForContextualChat.value;
    if (updatedItem.list_item_id == listItem?.list_item_id) {
        listItem.list_item_description = updatedItem.list_item_description;
        return true
    }
    return false;
}

function updateListItemsConversationNameOpenedForEditing(updatedItem) {
    const listItemOpenedForEditing = listItemOpenedOnlyForEditor.value ?? listItemForContextualChat.value;
    if (!listItemOpenedForEditing || typeof listItemOpenedForEditing !== 'object')
        return;
    if (listItemOpenedForEditing?.list_item_id == updatedItem?.list_item_id) {
        listItemOpenedForEditing.list_item_name = updatedItem.list_item_name;

        /* if updateditem is the one currently opened and it's empty(fake) contextual conversation */
        if (!listItemOpenedForEditing.conversations?.length
            && listItemForContextualChat.value.list_item_id == updatedItem.list_item_id)
            return updateFakeConversationNameWith(updatedItem);

        listItemOpenedForEditing
            .conversations
            ?.forEach(conversation => {
                updateConversationNameWith(conversation.conversation_id, listItemOpenedForEditing.list_item_name)
            })
    }
}

async function saveListItemCurrentlyBeingEdited() {
    const listItem = listItemOpenedOnlyForEditor.value ?? listItemForContextualChat.value;
    updateItemDescription(listItem);
    return;
    /* const listIndex = listItem.listIndex;
    const itemId = listItem.list_item_id;
    if (listIndex == null || listIndex <= -1 || !itemId)
        return console.log("Can't save invalid listitem - saveListItemCurrentlyBeingEdited");
    const _list = list.value[listIndex];
    const itemIndex = _list.items.findIndex(item => item.list_item_id == itemId)
    const item = _list.items[itemIndex]; */
    const mapItem = getItemById(listItem.list_item_id);

    let data = {
        list_item_description: mapItem.list_item_description,
    };
    const url = prepareUrl(mapItem.list_id, mapItem.list_item_id, '/description') //`/boards/1/list/${data.list_id}/items/${data.list_item_id}`
    const { data: updatedItem } = await axios.patch(url, data);
    //updatedItem.listIndex = listIndex;
    //_list.items.splice(itemIndex, 1, updatedItem)
}

async function appendDecritpionToListItem(listIndex, itemId, descriptionToAppend) {
    if (listIndex < 0 || !itemId)
        return console.log("Can't save invalid listitem");
    const _list = list.value[listIndex];
    const itemIndex = getItemIndexInListById(_list, itemId);

    if (itemIndex == -1)
        return console.log("Invalid item in list: appendDescription", itemIndex, itemId);
    const item = _list.items[itemIndex]
    const list_id = _list.list_id,
        list_item_id = item.list_item_id

    let data = {
        list_item_description: (item.list_item_description ? item.list_item_description : '') + descriptionToAppend,
    };
    const url = prepareUrl(list_id, list_item_id, "/description");    //`/boards/1/list/${list_id}/items/${list_item_id}/description`
    const { data: updatedItem } = await axios.post(url, data);
    _list.items.splice(itemIndex, 1, updatedItem)
    return updatedItem;
}

async function archiveListItem(listIndex, itemId) {
    const _list = list.value[listIndex];
    const itemIndex = _list.items.findIndex(item => item.list_item_id == itemId)
    const item = _list.items[itemIndex]
    if (!item.isNew) {
        let data = { list_id: _list.list_id, list_item_id: item.list_item_id };
        const url = prepareUrl(data.list_id, data.list_item_id, "/archive") //`/boards/1/list/${data.list_id}/items/${data.list_item_id}/archive`
        const { data: listitem } = await axios.post(url, data);
        _list.items.splice(itemIndex, 1)
    } else {
        _list.items.splice(itemIndex, 1)
    }
    removeContextualConversationIfPresentForItem(item);
}

async function deleteListItem() { }

function removeContextualConversationIfPresentForItem(listItem) {
    if (isListItemOpenedForContextualChat(listItem)) {
        resetListItemForContextualChat();
        if (!listItem.conversations?.length)
            return removeFakeConversation();
    }
    removeConversationById(getFirstConversationIdForListItem(listItem))
}

function isItemDescriptionNotEmpty(listIndex, listItemId) {
    const _list = list.value[listIndex];
    const item = findListItemByIdInList(_list, listItemId);

    return item && item.list_item_description
}

async function shuffleListItemsForList(target_list_index) {
    //console.log( target_list_index, "shuffling in list");
    const _list = list.value[target_list_index];
    //const itemIndex = _list.items.findIndex(item => item.list_item_id == itemId)
    let listOrder = [];
    _list.items.forEach((item, index) => listOrder.push({ list_item_id: item.list_item_id, list_item_sequence_id: index + 1 }))
    let data = { target_list_id: _list.list_id, new_list_items_order: listOrder };
    const url = prepareUrl(data.target_list_id, null, "/shuffle") //`/boards/1/list/${data.target_list_id}/shuffle`
    const { data: listitems } = await axios.post(url, data);
    _list.items = listitems
}

async function shuffleActiveBoardList() {
    let url = prepareUrl(null, null, `/shuffle-list`); // `/boards/{board_id}/list-shuffle`

    const activeBoard = currentActiveBoard();

    let newListSequnece = [];
    let newSequence = 1;
    activeBoard
        .list
        .forEach(list => {
            if(  list.is_fake )
                return;
            list.list_sequence_id = newSequence++;
            newListSequnece.push({
                list_id: list.list_id,
                list_sequence_id: list.list_sequence_id
            });
        })
    await axios.post(url, { "list": newListSequnece });
    prepareMapForItemIdBoardAndList();
}

function setIsContextualForItem(itemId, is_contextual = false ) {
    const item = getItemById(itemId);
    item && (item.is_contextual = is_contextual)
}

async function moveListItemFromSourceToDestination(sourceIndex, destIndex, element) {

    const sourceList = list.value[sourceIndex];
    const destList = list.value[destIndex];

    const elementIndex = sourceList.items.findIndex(elmnt => elmnt.list_item_id == element.list_item_id);
    if (elementIndex == -1)
        return console.log("Element is not found...");
    const elementToMove = sourceList.items.splice(elementIndex, 1)[0];
    destList.items.splice(0, 0, elementToMove);
    await shuffleListItemsForList(destIndex);
}

async function createConversationForOpenedListItem(participant_user_ids = []) {
    const item = listItemForContextualChat.value
    //return console.log( item, listItemForContextualChat.value , "creating-conversation");
    const url = prepareUrl(item.list_id, item.list_item_id, "/conversations"); //`/boards/1/list/${item.list_id}/items/${item.list_item_id}/conversations`
    const { data: conversation } = await axios.post(url, { participant_user_ids });
    if (!Array.isArray(item.conversations))
        item.conversations = [];

    item.conversations.push(conversation);
    return conversation
}

async function loadConversationsForListItemForContextualChat(listIndex, listItem) {
    const _list = list.value[listIndex];
    const itemIndex = _list.items.findIndex(item => item.list_item_id == listItem.list_item_id)
    const item = _list.items[itemIndex]
    const url = prepareUrl(item.list_id, item.list_item_id, "/conversations"); //`/boards/1/list/${item.list_id}/items/${item.list_item_id}/conversations`

    const { data: listItemWithConversations } = await axios.get(url);
    item.listIndex = listIndex;
    item.conversations = listItemWithConversations.conversations;
    _list.items.splice(itemIndex, 1, item)
    return item;
}

/* contexual chat related things */
async function getUnorganizedListIndexOrCreate() {
    const list_name = 'Unorganized';
    let listIndex = list.value.findIndex(_list => _list.list_name == list_name)
    // if not found, create newo ne
    //console.log("found-list", listIndex)
    if (listIndex < 0) {
        const data = { list_name }
        const url = prepareUrl(null, null, "/list");
        const { data: listNew } = await axios.post(url, data);
        listNew.items = [];
        list.value.unshift(listNew)
        updateListSequence();
        listIndex = 0
    }
    return listIndex
}

async function addNewItemForListAndOpenContextualChat(list_item_name, list_item_description) {

    const listIndex = await getUnorganizedListIndexOrCreate();

    const _list = list.value[listIndex];
    const data = {
        list_item_name,
        list_item_description
    }
    //_list.items.unshift(data);
    const itemIndex = await saveListItemAndUpdateListSequence(listIndex, data)
    openListItemForContextualChat(listIndex, _list.items[itemIndex]);

}

async function updateItemInListAndOpenContexualChat(listIndex, itemId, descriptionToAppend) {
    const _list = list.value[listIndex];
    const item = findListItemByIdInList(_list, itemId);
    if (!item) {
        alert("Item not found");
        return;
    }
    item.newName = item.list_item_name;
    if (!item.list_item_description)
        item.list_item_description = '';
    item.list_item_description = item.list_item_description + descriptionToAppend;
    //console.log(item, listIndex, descriptionToAppend, itemId  )
    //return;
    const liteItem = await saveListItem(listIndex, item.list_item_id)

    /* if this item opened for editng only (now it's possobel to open item only for editing and have chat in other dm/group/item )
        then keep previous state as it was
    */
    if (updateListItemDescriptionOpenedForEditing(liteItem))
        return openConversationOnly();

    openListItemForContextualChat(listIndex, liteItem);
}

function setContextualListItemWith(listIndex, listItem) {
    if( listItemForContextualChat.value?.is_contextual)
        delete listItemForContextualChat.value?.is_contextual;
    resetListItemOpenedForEditing();
    listItemForContextualChat.value = listItem  //_list.items.find( item => item.list_item_id == itemId)
    listItemForContextualChat.value.listIndex = listIndex;
    listItemForContextualChat.value.is_contextual = true;
}

function listItemIdOpenedForEditing() {
    return (listItemOpenedOnlyForEditor.value || listItemForContextualChat.value)?.list_item_id;
}

function listItemNameOpenedForEditing() {
    return (listItemOpenedOnlyForEditor.value || listItemForContextualChat.value)?.list_item_name;
}

function listItemIdOpenedForContextualChat() {
    return listItemForContextualChat.value.list_item_id;
}

function listItemNameOpenedForContextualChat() {
    return listItemForContextualChat.value.list_item_name
}

function prepareForContextualConversationWith(listItem, conversation_id, isEditor2Open = true) {

    isEditor2Open ?
        setSidePanelCurrentViewWithEditor()
        : "don't open editor and keep sidepanel opened with items if Clicked on Sidepanel";

    setUserContextualListItemId(listItem.list_item_id);
    setContextualConversation(conversation_id, listItem.list_item_name);
}

async function openListItemForContextualChat(listIndex, listItem) {

    //const item = await loadConversationsForListItemForContextualChat(listIndex, listItem)
    //listItem.conversations = item.conversations;
    setContextualListItemWith(listIndex, listItem)
    prepareForContextualConversationWith(listItem, getFirstConversationIdForListItem(listItem))
}

function getFirstConversationIdForListItem(listItem) {
    const itemConversations = listItem.conversations;
    return itemConversations && itemConversations[0]?.conversation_id;
}

function findListItemAndOpenForChatByItemIdAndConversationId(listItemId, conversation_id) {
    const { listIndex, listItem } = getListIndexAndListItemForItemId(listItemId);
    if (listIndex < 0 || !listItem) {
        console.log("No item found");
        return null;
    }
    /* if empty conversation or it belongs to conversation */
    if (!conversation_id || doesListItemHaveConversation(listItem, conversation_id)) {
        openListItemForChatAndEditor(listIndex, listItem);
        return { listIndex, listItem, conversation_found: true };
    }
    return { listIndex, listItem, conversation_found: false };;
}

function setContextualItemById(itemId) {
    //console.log("setContextualItemById ... ", itemId)
    //reset last item's contextual chat
    //const currentContextualItem = getItemById(listItemForContextualChat.value.list_item_id);
    //console.log("current contextual item .. ",  listItemForContextualChat.value, currentContextualItem )
    //currentContextualItem && (currentContextualItem.is_contextual = false);

    const itemToBeContextual = getItemById(itemId);
    //itemToBeContextual && (itemToBeContextual.is_contextual = true);
    listItemForContextualChat.value = itemToBeContextual;
    //console.log("next to be contextual item .. ", listItemForContextualChat.value, itemToBeContextual)
}

function openItemToChat(item) {
    setContextualItemById(item.list_item_id);
    const { list_item_id } = listItemForContextualChat.value
    setUserContextualListItemId(list_item_id)
    setConversationForItemAsCurrent(item);
    saveCurrentState()
}

function openItemToChatAndEdit(item) {
    loadItemIntoEditor(item)
    openItemToChat(item)
}

function openItemToEdit(itemToEdit, isToOpenChatPanel = false) {
    loadItemIntoEditor(itemToEdit);
    isToOpenChatPanel && openConversationOnly();
    saveCurrentState();
}

function loadItemIntoEditor(itemToEdit) {
    const { board, item} = getBoardAndItemByItemId(itemToEdit.list_item_id)
    if (!board || !item)
        return console.log("board or item not found", {  board, item});
    openBoard(board)
    setSidePanelCurrentViewWithEditor();
    listItemOpenedOnlyForEditor.value = item;   //_list.items.find( item => item.list_item_id == itemId)
    const isDescriptionFetched = Object.hasOwnProperty.call(item, 'list_item_description')
    if ( isDescriptionFetched ) {
        return
    }
    item.is_fetching_description_in_progress = true;
    item.is_error = false;
    getItemDescription(item)
        .then( itemData => {
            if(!itemData)
                item.is_error = true;
            item.is_fetching_description_in_progress = false;
        } );
}

function openListItemForChat(listIndex, listItem, isCloseSidePanel = false) {
    setContextualListItemWith(listIndex, listItem)
    const itemConversations = listItem.conversations;
    const conversation_id = itemConversations && itemConversations[0]?.conversation_id;
    prepareForContextualConversationWith(listItem, conversation_id, false)

    isCloseSidePanel && closeSidePanel();
}

function openListItemForChatAndEditor(listIndex, listItem) {
    setContextualListItemWith(listIndex, listItem)
    const itemConversations = listItem.conversations;
    const conversation_id = itemConversations && itemConversations[0]?.conversation_id;
    prepareForContextualConversationWith(listItem, conversation_id)
}

function resetListItemForContextualChat() {
    //console.log(" resetListItemForContextualChat ");
    listItemForContextualChat.value = {};
    setUserContextualListItemId(null);
}

function openListItemForEditingById(listItemId) {
    if (listItemId == null || listItemId <= 0)
        return console.log("Invalid item to open for Editing", listItemId);
    const { listIndex, listItem } = getListIndexAndListItemForItemId(listItemId);
    if (listIndex < 0 || listItem == null)
        return console.log("Not found item to open for editor", listIndex, listItem);
    openListItemOnlyForEditing(listIndex, listItem);
}

function openListItemOnlyForEditing(listIndex, listItem) {
    if (listIndex == null || listIndex == -1 || listItem == null)
        return;
    listItemOpenedOnlyForEditor.value = listItem  //_list.items.find( item => item.list_item_id == itemId)
    listItemOpenedOnlyForEditor.value.listIndex = listIndex;
    setSidePanelCurrentViewWithEditor();
}

function resetListItemOpenedForEditing() {
    listItemOpenedOnlyForEditor.value = null;
}

function getListIndexAndItemDetailsByConversationId(list_item_id, conversation_id) {
    const { listIndex, listItem } = getListIndexAndListItemForItemId(list_item_id);

    if (listIndex == -1 || !listItem)
        return;

    let conIndex = -1;
    if (conversation_id)
        conIndex = getConversationIndexInListItemById(listItem, conversation_id);
    return { listIndex, listItem, success: conIndex != -1 }
}

function findAndSetListItemForContextualConversation(conversation_id) {
    if (!conversation_id)
        return
    list.value.find((_list, listIndex) => {
        const foundListItem =
            _list.items
                ?.find(listItem => listItem.conversations
                    ?.find(conv => conv.conversation_id == conversation_id)
                )
        if (foundListItem)
            setContextualListItemWith(listIndex, foundListItem);
        return foundListItem;
    })
}

function getListIndexAndItemForConversationId(conversation_id) {
    let listIndex = -1, listItem = null;
    if (!conversation_id)
        return { listIndex, listItem };

    listIndex = list.value.findIndex((_list) => {
        return listItem = _list.items?.find(_listItem => doesConversationByIdBelongToListItem(conversation_id, _listItem))
    })
    return listItem && listIndex != -1 ? { listIndex, listItem } : { listIndex: -1, listItem: null };
}

/*
    this is called while restoring chat state from last session
    so Converstions - ordered by last_message_sent
    but list item might have been opened without sending a message,
    so here top conversation doesn't belong to this opend list item
    in this case :  open first conversation of that list item.
*/

function loadListItemAndConversationFor(list_item_id, conversation_id) {
    const { listIndex, listItem } = getListIndexAndListItemForItemId(list_item_id);

    if (listIndex == -1 || !listItem)
        return;

    let conIndex = -1;
    if (conversation_id)
        conIndex = getConversationIndexInListItemById(listItem, conversation_id);

    setContextualListItemWith(listIndex, listItem)
    // if no conversation found/created, then open a fake conversation
    //no message was sent but it was opened
    if (conIndex <= -1)
        conversation_id = listItem.conversations[0]?.conversation_id;

    prepareForContextualConversationWith(listItem, conversation_id);
}

async function appendDecritpionToListItemOpenedInEditor(descriptionToAppend) {
    const listItem = listItemOpenedOnlyForEditor.value ?? listItemForContextualChat.value;
    const listIndex = listItem.listIndex;
    const itemId = listItem.list_item_id;

    const updatedItem = await appendDecritpionToListItem(listIndex, itemId, descriptionToAppend)
    updateListItemDescriptionOpenedForEditing(updatedItem);
    return updatedItem;
}

function isListItemOpenedForContextualChat(listItem) {
    return listItemForContextualChat.value?.list_item_id == listItem?.list_item_id;
}

function isContextualListItemNotSet() {
    return !listItemForContextualChat.value
}

function updateEditorScrollPosition(scrollTop) {
    cache.setEditorDetails({ item_id: listItemOpenedOnlyForEditor.value.list_item_id, scrollTop})
}

function getEditorScrollPostion() {
    const editorDetails = cache.getEditorDetails();
    let scrollTop = 0;
    if( editorDetails?.item_id && editorDetails.item_id == listItemOpenedOnlyForEditor.value.list_item_id)
        scrollTop = editorDetails.scrollTop ?? 0
    return scrollTop;
}

const hasListItemSetForContextualChat = computed(() => Object.keys(listItemForContextualChat.value).length)

export {
    listLoading,
    list,
    getListIndexById,
    fetchList,
    createList,
    editList,
    updateList,
    cancelEditList,
    archiveList,
    deleteList,
    updateListSequence,
    createNewListAndAddItem,

    createItem,
    updateItemName,
    updateItemDescription,
    archiveItem,
    deleteItem,
    shuffleItemsForList,
    shuffleActiveBoardList,
    setIsContextualForItem,
    moveItemFromListToListAt,
    moveItemFromListToNewList,

    setContextualItemById,
    openItemToChat,
    openItemToEdit,
    loadItemIntoEditor,
    openItemToChatAndEdit,
    resetContextualItem,
    isItemContextual,
    isContextualItemSet,

    sync_addList,
    sync_updateList,
    sync_removeList,
    sync_addItem,
    sync_updateItem,
    sync_removeItem,

    addListItem,
    editListItem,
    saveListItem,
    updateNameForListItem,
    appendDecritpionToListItem,
    updateDescriptionForListItem,
    saveListItemCurrentlyBeingEdited,
    archiveListItem,
    deleteListItem,
    isItemDescriptionNotEmpty,
    shuffleListItemsForList,
    moveListItemFromSourceToDestination,

    getListIndexAndListItemForItemId,
    doesListItemHaveConversation,
    doesConversationByIdBelongToListItem,
    doesListItemHaveAnyConversations,

    listItemOpenedOnlyForEditor,
    openListItemOnlyForEditing,
    openListItemForEditingById,
    resetListItemOpenedForEditing,

    listItemIdOpenedForEditing,
    listItemNameOpenedForEditing,
    listItemIdOpenedForContextualChat,
    listItemNameOpenedForContextualChat,
    openListItemForContextualChat,
    findListItemAndOpenForChatByItemIdAndConversationId,
    openListItemForChat,
    openListItemForChatAndEditor,
    resetListItemForContextualChat,
    hasListItemSetForContextualChat,
    listItemForContextualChat,
    isListItemOpenedForContextualChat,
    loadListItemAndConversationFor,
    getListIndexAndItemDetailsByConversationId,
    findAndSetListItemForContextualConversation,
    getListIndexAndItemForConversationId,
    isContextualListItemNotSet,
    addNewItemForListAndOpenContextualChat,
    updateItemInListAndOpenContexualChat,
    createConversationForOpenedListItem,

    updateEditorScrollPosition,
    getEditorScrollPostion,
}

