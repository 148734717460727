export default {
    clone: function (arg) {
        return JSON.parse(JSON.stringify(arg))
    },

    scrollTo: function (id) {
        document.querySelector('#' + id)?.scrollIntoView()
    },

    getDifference: function (args1, args2){
        return args1.filter(x => !args2.includes(x))
    },
  
    getSimilarity: function (args1, args2) {
        return args1.filter(x => args2.find(y => y.id === x.id))
    },

    capitalize: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
}
