import axios from 'axios';
import converationsAPi from '../composition-api/conversations';
const { isConversationOpened,
    getCurrentConversationObj } = converationsAPi()
import {getSidePanelCurrentview,
    isSidePanelCurrentViewEditor
    } from '../composition-api/helpers';
import {listItemIdOpenedForContextualChat,
    listItemIdOpenedForEditing
    } from '../composition-api/list';
import BoardsApi from '../composition-api/boards';
const { currentActiveBoardId } = BoardsApi()

class User {

    loggedInUser;
    is_conversation_opened = false;
    list_item_id_opened_for_chat  = null;

    constructor(loggedInUser) {
        this.loggedInUser = loggedInUser;
        //this.registerToWindowReloadEventToSaveCurrentSession()
    }

    get id() {
        return this.loggedInUser?.id
    }

    get name() {
        return this.loggedInUser?.name
    }

    get settings() {
        return this.loggedInUser.settings
    }

    setSettings(settings) {
        this.loggedInUser.settings = settings;
    }

    getKeyValue(keyName) {
        const settings = this.loggedInUser?.settings
        if( !settings )
            return undefined;

        return settings[keyName];
    }

    setIsConversationOpened(isOpened ) {
        this.is_conversation_opened = isOpened;
    }

    setContextualListItemId(list_item_id = null) {
        this.list_item_id_opened_for_chat = list_item_id;
    }

    getCurrentConversationState() {
        return {
            is_conversation_opened: this.is_conversation_opened,
            list_item_id_opened_for_chat : this.list_item_id_opened_for_chat
            //list_item_id_opened_for_chat : null
        }
    }

    boardIdOpenedInLastSession() {
        return this.getKeyValue('board_id_opened_before_closing_in_last_session')
    }

    setOpenedBoardId(board_id) {
        this.settings.board_id_opened_before_closing_in_last_session = board_id;
    }

    wasConversationOpenedInLastSession() {
        return this.getKeyValue('was_conversation_opened_before_closing_in_last_session');
    }

    listItemIdOpenedInLastSession() {
        return this.getKeyValue('prospus_list_item_id_opened_before_closing_in_last_session')
    }

    cursorInChat() {
        return  this.cursorInEditor() != true;
    }

    cursorInEditor() {
        return this.sidePanelOpenedWith() == 'editor'
    }

    conversationIdOpened() {
        return this.getKeyValue('conversation_id_opened_before_closing_in_last_session');
    }

    listItemIdOpenedForChat() {
        return this.getKeyValue('list_item_id_opened_for_chat_before_closing_in_last_session');
    }

    listItemIdOpenedInEditor() {
        return this.getKeyValue('list_item_id_opened_for_editing_before_closing_in_last_session');
    }

    isSidePanelOpenedWithEditor() {
        return this.cursorInEditor();
    }

    sidePanelOpenedWith() {
        return this.getKeyValue('side_panel_opened_with');
    }

    prepareCurrentSessionState() {
        const conObj = getCurrentConversationObj();
        //console.log("list item for ediotr @ ", listItemIdOpenedForEditing(), currentActiveBoardId(), getSidePanelCurrentview())
        return {
            board_id_opened : currentActiveBoardId(),
            is_conversation_opened: isConversationOpened.value,
            conversation_id_opened: conObj?.isFake() ? null : conObj.id,
            side_panel_opened_with: getSidePanelCurrentview(),
            list_item_id_opened_for_chat: listItemIdOpenedForContextualChat(),
            list_item_id_opened_for_editing: isSidePanelCurrentViewEditor ? listItemIdOpenedForEditing() : null,
            cusrsor_in: isSidePanelCurrentViewEditor ? 'editor' : 'chat'
        }
    }

    twilioIdentity() {
        return this.loggedInUser.twilio_identity_alias;
    }

    registerToWindowReloadEventToSaveCurrentSession() {

        /* lifecycle.addEventListener('statechange', (event) => {
            console.log("stateChange", event.newState);
            if (['hidden', 'terminated'].includes(event.newState))
                this.saveCurrentSession();
        }); */

        window.addEventListener("beforeunload", (e) => {
            e.returnValue = "Updating current state";
            this.saveCurrentSession();

            //var confirmationMessage = "\o/";
            //Gecko + IE//Webkit, Safari, Chrome
            (e || window.event).returnValue = null;
            //return null;
        });
    }

    saveCurrentSession() {
        //console.log( "current-session-data", this.prepareCurrentSessionState())
        axios.post("/current-session", this.prepareCurrentSessionState())
                //.then( res => console.log("current session stored") );
    }
}

export default User;
