class Cache {

    get(key) {
        return localStorage.getItem(key)
    }

    set(key, value) {
        localStorage.setItem(key, value);
    }

    getObject(key) {
        return this.parseObject(key);
    }

    parseObject(key) {
        const tokenDetails = this.get(key)
        if( !tokenDetails )
            return {};

        try {
            return JSON.parse(tokenDetails);
        } catch (error) {
            console.log("return", {})
            return {};
        }
    }

    setToken(key, tokenDetails) {
        const token = tokenDetails.token;
        const validityInSeconds = tokenDetails.ttl;
        if(!token)
            return console.info("no token");
        var ttl = new Date();
        ttl.setSeconds(ttl.getSeconds() + validityInSeconds)
        const data = JSON.stringify({ token, ttl})
        this.set(key, data)
    }

    getToken(userIdentityAliasKey) {
        const tokenDetails = this.parseObject(userIdentityAliasKey)
        return tokenDetails.token;
    }

    hasValidToken(userIdentityAliasKey) {
        const tokenDetails = this.parseObject(userIdentityAliasKey);
        var expiresAt = new Date(tokenDetails.ttl);
        const now = new Date();
        return expiresAt > now ? tokenDetails.token : false;
    }

    setApiToken(token) {
        this.set("api_token", token);
    }

    getApiToken() {
        return this.get("api_token");
    }

    setTwilioIdentity(userIdentity) {
        this.set('user_identity', userIdentity)
    }

    getTwilioIdentity() {
        return this.get('user_identity')
    }

    /* panel related helpers */
    // Chat panel
    conversationOpened() {
        this.set('conversation_opened', 1)
    }

    conversationClosed() {
        this.set('conversation_opened', 0)
    }

    isConversationStateValid() {
        return this.get('conversation_opened') != null
    }

    getConversationState() {
        return parseInt(this.get('conversation_opened')) ? true : false;
    }

    // Side panel
    setSidePanelState(isClosed = true ) {
        this.set('side_panel_opened', isClosed)
    }
    getSidePanelState() {
        return parseInt(this.get('side_panel_opened')) ? true : false
    }
    sidePanelOpenedWith(currentView) {
        this.set('sidepanel_view', currentView)
    }

    getSidePanelView() {
        return this.get('sidepanel_view')
    }

    setConversationDrafts(conversationDrafts) {
        this.set('conversation_drafts', JSON.stringify(conversationDrafts));
    }

    getConversationDrafts() {
        try {
            return  JSON.parse(this.get('conversation_drafts'));
        }catch(error) {
            return {}
        }
    }

    setActiveConversation(conversation) {
        this.set('active_conversation', JSON.stringify(conversation));
    }

    getActiveConversation() {
        try {
            return JSON.parse(this.get('active_conversation'))
        }catch(error) {
            return {}
        }
    }

    setEditorDetails(editorDetails) {
        this.set('editor_details', JSON.stringify(editorDetails))
    }

    getEditorDetails() {
        return  JSON.parse(this.get('editor_details'))
    }
}

export default Cache;
