import axios from "axios";
import { ref, computed } from "vue";
import { fetchList, list, openItemToChat, openItemToEdit } from "./list";
import ConversationApi from "./conversations";
const { getItemIdIfCurrentConversationIsContextual } = ConversationApi();
import LoggedinUserApi from "./logged-in-user";
import { openSidePanel, setSidePanelCurrentViewWithItems } from "./helpers";
const { loggedInUser, saveCurrentState } = LoggedinUserApi();
const itemsBoardOpenWith = ref("workspace-list");
const workspaceList = ref([]);
const workspace = ref([]);
const activeBoard = ref({});
const mapForItemIdBoardAndList = new Map();
const mapForListIdAndList = new Map();
const mapForBoardIdAndBoard = new Map();
//const itemIdForChat = ref(null)
//const itemIdForEditor = ref(null);

export default function BoardsApi() {
    const workspaceName = computed(() => workspace.value.workspace_name);
    const workspaceId = computed(() => workspace.value.workspace_id);
    const isAnyWorkspaceSelected = computed(
        () => workspace.value?.workspace_id
    );
    /* function setItemsForChatAndEditorToRestoreAfterWorkspaceFetched(itemIdChat, itemIdEdit) {
        itemIdForChat.value = itemIdChat
        itemIdForEditor.value = itemIdEdit;
        //console.log("items set to restore ",  itemIdForChat.value , itemIdForEditor.value)
    }

    async function restoreIfItemForChatOrEditIfNotRestored() {

        //console.log("restoing items after fetched")
        const chatItemId = itemIdForChat.value ?? getItemIdIfCurrentConversationIsContextual()
        if (chatItemId) {
            const { item, board } = getBoardAndItemByItemId(chatItemId)
            //board && openBoard(board, false)
            item && openItemToChat(item);
            itemIdForChat.value = null;
            //console.log("itemToChat")
        }

        if (itemIdForEditor.value) {
            const { item, board } = getBoardAndItemByItemId(itemIdForEditor.value)

            if(!board || !item)
                return console.log("can't be loaded into editor");
            openBoard(board, false)
            openItemToEdit(item)
            openSidePanel()
            itemIdForEditor.value = null;
            //console.log("itemToEdit")
        }

    } */

    function addFakeBoardList() {
        workspace.value.boards.push({
            is_fake: true,
            board_category: "Add New List",
            boards: [],
        });
    }

    const papareBoards = computed(() => {
        const boards = {};
        workspace.value.boards?.forEach(
            (boardCategoryList, board_category_index) => {
                const board_category = boardCategoryList.board_category;
                boards[board_category] = [];
                boardCategoryList.boards.forEach((board, board_index) => {
                    boards[board_category].push({
                        id: board.board_id,
                        name: board.board_name,
                    });
                });
                !boards[board_category].length && delete boards[board_category];
            }
        );
        //console.log("preapring boards --", boards);
        return boards;
    });

    function prepareMapForItemIdBoardAndList() {
        //window.logTimeWith("prepatingBoards .. started")
        workspace.value.boards?.forEach(
            (boardCategoryList, board_category_index) => {
                const board_category = boardCategoryList.board_category;
                boardCategoryList.boards.forEach((board, board_index) => {
                    setBoardAsCurrentActiveBoardIfSelectedInLastSession(board);
                    board?.list?.forEach((list, list_index) => {
                        list?.items?.forEach((item, list_item_index) => {
                            (item.list_id = list.list_id), (item.list = list);
                            item.board_id = board.board_id;
                            item.board_category = board_category;
                            item.board_name = board.board_name;
                            item.conversation_id = null;
                            /* board_category_index,
                                            board_index,
                                            list_index,
                                            list_item_index */
                            mapForItemIdBoardAndList.set(
                                item.list_item_id,
                                item
                            );
                        });
                        list.board = board;
                        list.board_category = board_category;
                        list.list_id &&
                            mapForListIdAndList.set(list.list_id, list);
                    });
                    board.board_category = board_category;
                    updateBoardInMap(board);
                });
            }
        );
        //window.logTimeWith("prepatingBoards .. ended")
        //window.getItemById= getItemById;
        //window.getListById = getListById;
        //window.getBoardById = getBoardById;
    }

    function setBoardAsCurrentActiveBoardIfSelectedInLastSession(board) {
        const authUser = loggedInUser();
        if (
            !authUser ||
            !authUser.boardIdOpenedInLastSession ||
            authUser.boardIdOpenedInLastSession() != board.board_id
        )
            return;

        openBoard(board, false);
    }

    function updateBoardInMap(board) {
        mapForBoardIdAndBoard.set(board.board_id, board);
    }

    function getBoardCategoryDetailsByCategory(board_category) {
        return workspace.value.boards?.find(
            (boardCategoryList) =>
                boardCategoryList.board_category == board_category
        );
    }

    function getBoardById(boardId) {
        let board = null;
        workspace.value.boards?.findIndex((boardCategoryList) => {
            return (board = boardCategoryList.boards.find(
                (tmpBoard) => tmpBoard.board_id == boardId
            ));
        });
        return board;
    }

    function getListById(listId) {
        let listFound = null;
        workspace.value.boards?.findIndex((boardCategoryList) => {
            boardCategoryList.boards.find(
                (board) =>
                    (listFound = board.list?.find(
                        (list) => list.list_id == listId
                    ))
            );
            return listFound;
        });
        if (!listFound) return console.log("List not found for ", { listId });
        return listFound;

        return mapForListIdAndList.get(parseInt(listId));
    }

    function removeListById(listId) {
        return mapForListIdAndList.delete(listId);
    }

    function getItemById(itemId) {
        //console.log("getItemById::start : ", itemId)
        let itemFound = null;
        workspace.value.boards?.find((boardCategoryList) => {
            //console.log(" searching in category ", boardCategoryList.board_category)
            return boardCategoryList.boards.find((board) => {
                //console.log("searching in board ", board.board_name);
                return board.list?.find((list) => {
                    itemFound = list.items?.find(
                        (tmpItem) => tmpItem.list_item_id == itemId
                    );
                    //console.log("item found " , itemFound, list.items)
                    return itemFound;
                });
            });
        });
        //console.log("getItemByID:end : ", boardCatindex)
        /* if (!itemFound)
            console.log("getItemById: Item not found", { itemId }); */
        return itemFound;

        //return mapForItemIdBoardAndList.get(parseInt(itemId));
    }

    function getBoardAndItemByItemId(itemId) {
        //console.log("getItemById::start : ", itemId)
        let itemFound = null,
            boardFound = null;
        workspace.value.boards?.find((boardCategoryList) => {
            //console.log(" searching in category ", boardCategoryList.board_category)
            return boardCategoryList.boards.find((board) => {
                boardFound = board;
                //console.log("searching in board ", board.board_name);
                return board.list?.find((list) => {
                    itemFound = list.items?.find(
                        (tmpItem) => tmpItem.list_item_id == itemId
                    );
                    //console.log("item found " , itemFound, list.items)
                    return itemFound;
                });
            });
        });
        //console.log("getItemByID:end : ", boardCatindex)
        if (!itemFound) boardFound = null;
        //console.log("getItemById: Item not found", { itemId }); */
        return { item: itemFound, board: boardFound };

        //return mapForItemIdBoardAndList.get(parseInt(itemId));
    }

    function removeBoardFromCategory(board_category, board_id) {
        const boardCategoryDetails =
            getBoardCategoryDetailsByCategory(board_category);
        if (!boardCategoryDetails)
            return console.log(
                "removeBoardFromCategory: board category not found to delete board"
            );
        const index = boardCategoryDetails.boards?.findIndex(
            (board) => board.board_id == board_id
        );
        if (index > -1) return boardCategoryDetails.boards.splice(index, 1)[0];

        console.log(
            "removeBoardFromCategory: board not found to delete in category"
        );
    }

    function removeListFromBoard(board_id, list_id) {
        const board = getBoardById(board_id);
        if (!board)
            return console.log(
                "removeListFromBoard: board not found to delete list"
            );
        const index = board.list.findIndex((list) => list.list_id == list_id);
        if (index > -1) return board.list.splice(index, 1)[0];
        console.log("removeListFromBoard: list not found to delete in Board");
    }

    function removeItemFromList(list_id, item_id) {
        const list = getListById(list_id);
        if (!list)
            return console.log(
                "removeItemFromList: list not found to delete item"
            );
        const index = list.items.findIndex(
            (item) => item.list_item_id == item_id
        );
        if (index > -1) return list.items.splice(index, 1)[0];
        console.log("removeItemFroList: item not found to delete in list");
    }

    function removeItemById(itemId) {
        return mapForItemIdBoardAndList.delete(itemId);
    }

    function sync_updateWorkspaceBoardCategoriesReordering(
        updatedBoardCategorySequence
    ) {
        let boardCategoriesReordered = [];
        let bcFound;

        updatedBoardCategorySequence.forEach((boardCategorySequenceDetail) => {
            bcFound = getBoardCategoryDetailsByCategory(
                boardCategorySequenceDetail.board_category
            );

            bcFound && boardCategoriesReordered.push(bcFound);
        });

        if (!boardCategoriesReordered.length)
            return console.log(
                "sync_updateWorkspaceBoardCategoriesReordering  BoardCategoryReordered list is empty"
            );

        workspace.value.boards.splice(
            0,
            workspace.value.boards.length - 1,
            ...boardCategoriesReordered
        );
    }

    function sync_updateBoardCategoryBoardsOrdering(
        board_category,
        updatedBoardsSequence
    ) {
        const boardCategoryDetails =
            getBoardCategoryDetailsByCategory(board_category);
        if (!boardCategoryDetails)
            return console.info("No Board category found to reorder boards");
        let boardsReordered = [];
        let boardFound;

        updatedBoardsSequence.forEach(
            ({ board_id, board_sequence_id, previous_board_category }) => {
                boardFound =
                    board_category == previous_board_category
                        ? boardCategoryDetails.boards.find(
                              (board) => board.board_id == board_id
                          )
                        : removeBoardFromCategory(
                              previous_board_category,
                              board_id
                          );
                if (!boardFound)
                    return console.log(`board Not Foud : ${board_id}`);

                boardFound.board_sequence_id = board_sequence_id;
                boardsReordered.push(boardFound);
            }
        );
        if (!boardsReordered.length)
            return console.log(
                "sync_updateBoardCategoryBoardsOrdering :  it's empty"
            );

        boardCategoryDetails.boards = boardsReordered;
    }

    function sync_updateBoardListOrdering(board_id, updatedListSequence) {
        const board = getBoardById(board_id);
        if (!board) return console.info("No Board found to reorder list");
        let listRereordered = [];
        let listFound;
        updatedListSequence.forEach(
            ({ list_id, list_sequence_id, previous_board_id }) => {
                listFound =
                    board_id == previous_board_id
                        ? board.list.find((list) => list.list_id == list_id)
                        : removeListFromBoard(previous_board_id, list_id);
                if (!listFound)
                    return console.log(`list Not found : ${list_id}`);

                listFound.list_sequence_id = list_sequence_id;
                listFound?.list_id && listRereordered.push(listFound);
                //console.log("listFound : ", listFound, listRereordered.length)
            }
        );
        if (!listRereordered.length)
            return console.log("sync_updateBoardListOrdering :  it's empty");

        board.list.splice(0, board.list.length - 1, ...listRereordered);
    }

    function sync_updateListItemsOrdering(list_id, updatedItemsSequence) {
        const list = getListById(list_id);

        if (!list) return console.log("No list found to reorder items");
        let itemsReordered = [];
        let itemFound;
        updatedItemsSequence
            //.sort( (item1, item2) => item1.list_item_sequence_id - item2.list_item_sequence_id)
            .forEach(
                ({ list_item_id, list_item_sequence_id, previous_list_id }) => {
                    itemFound =
                        list_id == previous_list_id
                            ? list.items.find(
                                  (item) => item.list_item_id == list_item_id
                              )
                            : removeItemFromList(
                                  previous_list_id,
                                  list_item_id
                              );
                    //console.log("itemFound to update:", itemFound, { list_item_id, list_item_sequence_id });
                    if (!itemFound) return;
                    itemFound.list_item_sequence_id = list_item_sequence_id;
                    itemFound?.list_item_id && itemsReordered.push(itemFound);
                }
            );

        if (!itemsReordered.length)
            return console.log("sync_updateListItemsOrdering :  it's empty");
        list.items = itemsReordered;
    }

    function sync_addBoard(board) {
        const boardCategoryList = workspace.value.boards.find(
            (boardCategoryList) =>
                boardCategoryList.board_category == board.board_category
        );
        board.list = [];
        if (!boardCategoryList) {
            workspace.value.boards.splice(
                workspace.value.boards.length - 1,
                0,
                {
                    board_category: board.board_category,
                    boards: [board],
                }
            );
        } else {
            const boardIndex = boardCategoryList.boards.findIndex(
                (tmp) => tmp.board_id == board.board_id
            );
            if (boardIndex == -1)
                boardCategoryList?.boards?.splice(
                    boardCategoryList.boards.length - 1,
                    0,
                    board
                );
        }
        updateBoardInMap(board);
    }

    function sync_updateBoard(board) {
        const mappedBoard = getBoardById(board.board_id);
        if (!mappedBoard) return console.log("Board not found to sync");

        mappedBoard.board_name = board.board_name;
        //console.log("sync_updateBoard ... ", mappedBoard, board)
    }

    function sync_removeOrRestoreBoard(boardToRemove) {
        const mappedBoard = getBoardById(boardToRemove.board_id);

        mappedBoard &&
            (mappedBoard.deleted_at = !boardToRemove.deleted_at
                ? null
                : boardToRemove.deleted_at);
        /*
        const listIndex = workspace.value.boards.findIndex(boardCategoryList => boardCategoryList.board_category == boardToRemove.board_category)
        if( listIndex < 0 )
            return console.log("No List for this board category");

        const boardCategoryList = workspace.value.boards[listIndex];
        if ( !boardCategoryList?.boards?.length )
            return console.log("No board found in this category");

        const boardIndex = boardCategoryList.boards.filter(board => board.board_id != boardToRemove.board_id);
        //delete list too if this is the only board in this board category
        if( boardCategoryList.boards.length <= 1 )
            workspace.value.boards.splice(listIndex, 1);

        mapForBoardIdAndBoard.delete(boardToRemove.board_id);
        */
    }

    function sync_boardCategoryUpdated(boardCategoryDetails) {
        const listIndex = workspace.value.boards.findIndex(
            (boardCategoryList) =>
                boardCategoryList.board_category ==
                boardCategoryDetails.old_board_category
        );
        if (listIndex < 0)
            return console.log("No List for this board category");

        const boardCategoryList = workspace.value.boards[listIndex];
        boardCategoryList.board_category =
            boardCategoryDetails.new_board_category;
    }

    function sync_boardCategoryToggleDeletedAtForBoards(categoryDetails) {
        const listIndex = workspace.value.boards.findIndex(
            (boardCategoryList) =>
                boardCategoryList.board_category ==
                categoryDetails.board_category
        );
        if (listIndex < 0)
            return console.log("No List for this board category");

        const boardCategoryList = workspace.value.boards[listIndex];

        boardCategoryDetails.boards?.forEach(
            (board) => (board.deleted_at = categoryDetails.deleted_at)
        );
    }

    function addListToBoardAndUpdateMap(list) {
        const board = getBoardById(list.board_id);
        if (!board) return console.log("Not board found to add List");
        if (!Array.isArray(board.list)) board.list = [];

        list.board = board;
        list.board_category = board.board_category;
        list.items = [];
        const listIndex = board.list.findIndex(
            (tmp) => tmp.list_id == list.list_id
        );
        if (listIndex >= 0) return;

        board.list.splice(board.list.length - 1, 0, list);
        //mapForListIdAndList.set(list.list_id, list);
    }

    function addItemToListAndUpdateMap(item) {
        const list = getListById(item.list_id);
        if (!list)
            return console.log(
                "addItemToListAndUpdateMap ..list not found ",
                item
            );
        if (!Array.isArray(list.items)) list.items = [];

        const itemIndex = list.items.findIndex(
            (tmp) => tmp.list_item_id == item.list_item_id
        );
        if (itemIndex >= 0) return; //console.log("itemIndex..", itemIndex, list);
        item.list = list;
        item.board_id = list.board?.board_id;
        item.board_category = list.board_category;
        item.board_name = list.board?.board_name;
        list.items.splice(0, 0, item);

        mapForItemIdBoardAndList.set(item.list_item_id, item);
    }

    function setConverstionForItem(itemId, conversation_id) {
        const item = getItemById(itemId);
        if (!item) return;
        item.conversation_id = conversation_id;
        //console.log("setConversationForItem---",  item, item.list_item_name, item.conversation_id)
    }

    function doesConversationBelongToItem(conversation_id, item_id) {
        return getItemById(item_id)?.conversation_id == conversation_id;
    }

    function processWorkspaceList(data) {
        workspaceList.value = [];
        for (const workspace_category in data)
            workspaceList.value.push({
                workspace_category,
                workspaces: data[workspace_category],
            });
    }

    async function fetchWorkspaces() {
        const { data } = await axios.get("/my-workspaces");
        processWorkspaceList(data);
    }

    async function fetchBoards() {
        fetchWorkspaces();
        const { data: body } = await axios.get("/boards");
        processMyWorkspaceBoards(body);
    }

    function processMyWorkspaceBoards(body) {
        if (!body.data?.workspace_id) return;

        const boardList = [];
        const respnose = body.data.boards;
        /* original response
            {
                "Board Category" => [ {board_name:'',.. },{}],
                "Board category 2" => [ { board_name: '', ...etc} ]...etc
            }
            -> transform to inorder to have list & items kind of ui for drag & dropping Board from one category to another
            [
                {
                    "board_category" => "Board category",
                    "boards" => [ { board_name: '' } ]
                },...etc
            ]
         */
        for (const key in respnose) {
            boardList.push({
                board_category: key,
                boards: respnose[key],
            });
        }

        workspace.value = {
            workspace_id: body.data.workspace_id,
            workspace_name: body.data.workspace_name,
            boards: boardList,
        };

        openItemsBoardWithWorkspace();
        prepareMapForItemIdBoardAndList();
        //restoreIfItemForChatOrEditIfNotRestored()

        addFakeBoardList();
    }

    async function createBoardListName(boardList) {
        boardList.board_category = boardList.new_board_category;
        delete boardList.is_fake;
        addFakeBoardList();
    }

    async function updateBoardListName(boardList) {
        if (!boardList.new_board_category) return;

        if (!boardList.boards?.length)
            return (boardList.board_category = boardList.new_board_category);

        const data = {
            board_category: boardList.new_board_category,
            old_board_category: boardList.board_category,
            board_ids: [],
        };
        boardList.boards.forEach((board) => {
            data.board_ids.push(board.board_id);
            board.board_category = boardList.new_board_category;
        });
        await axios.post(
            `/boards/update-category?workspace=${workspaceId.value}`,
            data
        );
        boardList.board_category = boardList.new_board_category;
    }

    async function archiveBoardListAtIndex(index) {
        const boardList = workspace.value.boards[index];
        if (!boardList.boards?.length)
            return workspace.value.boards.splice(index, 1);

        let board_ids = [];
        boardList.boards.forEach((board) => {
            board_ids.push(board.board_id);
        });
        await axios.post(
            `/boards/archive-category?workspace=${workspaceId.value}`,
            { board_ids }
        );
        boardList.boards.forEach((board) => {
            board.deleted_at = new Date();
        });
    }

    async function restoreBoardListAtIndex(index) {
        const boardList = workspace.value.boards[index];
        if (!boardList.boards?.length) return workspace.value.splice(index, 1);

        let board_ids = [];
        boardList.boards.forEach((board) => {
            board_ids.push(board.board_id);
        });
        await axios.post(
            `/boards/restore-category?workspace=${workspaceId.value}`,
            { board_ids }
        );
        boardList.boards.forEach((board) => {
            board.deleted_at = null;
        });
    }

    async function keepLastBoardListBeforeFakeOneIfMovedAfterAndUpdateBoardCategorySequenceIdForBoardsListAt(
        newIndex
    ) {
        // if newIndex is last element( i.e dragged and drooped after fake one)
        if (workspace.value.boards?.length - 1 == newIndex) {
            workspace.value.boards.splice(newIndex - 1, 1);
            addFakeBoardList();
            newIndex = newIndex - 1;
        }
        updateBoardCategeorySequenceId();
    }

    async function updateBoardCategeorySequenceId() {
        const updatedBoardCategorySequence = [];
        workspace.value.boards.forEach((boardList, index) => {
            if (boardList.is_fake) return;
            updatedBoardCategorySequence.push({
                board_category: boardList.board_category,
                board_category_sequence_id: index + 1,
            });
            //updateBoardCategeorySequenceIdForBoardsList(boardList, index+1)
        });

        axios.post(
            `/boards/update-category-sequence?workspace=${workspaceId.value}`,
            { board_categories_sequence: updatedBoardCategorySequence }
        );
    }

    async function updateBoardCategeorySequenceIdForBoardsList(
        boardList,
        board_category_sequence_id
    ) {
        const data = { board_category_sequence_id, board_ids: [] };
        boardList.boards?.forEach((board) => {
            data.board_ids.push(board.board_id);
        });
        await axios.post(
            `/boards/update-category-sequence?workspace=${workspaceId.value}`,
            data
        );
    }

    async function updateBoardSequenceIdForBoardsListAtIndex(listIndex) {
        const boardList = workspace.value.boards[listIndex];
        const boards = [];
        const board_category_sequence_id = listIndex + 1;
        boardList.boards.forEach((board, boardIndex) => {
            board.board_category_sequence_id = board_category_sequence_id;
            boards.push({
                board_id: board.board_id,
                board_category: boardList.board_category,
                board_category_sequence_id,
                board_sequence_id: boardIndex + 1,
            });
        });
        await axios.post(
            `/boards/update-board-sequence?workspace=${workspaceId.value}`,
            { boards }
        );
        //console.log( "updateBoardSequenceIdForBoardsListAtIndex-- " , listIndex, boardList.board_category)
    }

    async function createBoard(board, boardList) {
        if (!board.new_name) return;
        const { data: newBoard } = await axios.post(
            `/boards?workspace=${workspaceId.value}`,
            {
                board_name: board.new_name,
                board_category: boardList.board_category,
            }
        );
        if (!boardList.boards) boardList.boards = [];
        boardList.boards.splice(0, 1, newBoard);
    }

    async function updateBoard(board, boardList) {
        /* if name is empty or no chnage in name */
        if (!board.new_name || board.new_name == board.board_name) return;
        const { data: updatedBoard } = await axios.patch(
            `/boards/${board.board_id}?workspace=${workspaceId.value}`,
            { board_name: board.new_name }
        );
        const index = boardList.boards.findIndex(
            (board) => board.board_id == updatedBoard.board_id
        );
        if (index < 0) return; //updateBoardInMap(updatedBoard);
        board.board_name = updatedBoard.board_name;
        //boardList.boards.splice(index, 1, updatedBoard);
        //updateBoardInMap(updatedBoard);
    }

    async function archiveBoard(boardToArchive, boardList) {
        const { data: boardUpdated } = await axios.post(
            `/boards/${boardToArchive.board_id}/archive?workspace=${workspaceId.value}`
        );
        replaceBoardWith(boardUpdated, boardList);
    }

    async function restoreBoard(boardToRestore, boardList) {
        const { data: boardUpdated } = await axios.post(
            `/boards/${boardToRestore.board_id}/restore?workspace=${workspaceId.value}`
        );
        //console.log("restoring board...", boardToRestore);
        replaceBoardWith(boardUpdated, boardList);
    }

    function replaceBoardWith(board2Update, boardList) {
        const boardIndex = boardList.boards.findIndex(
            (board) => board.board_id == board2Update.board_id
        );
        if (boardIndex == -1) return;
        boardList.boards.splice(boardIndex, 1, board2Update);
    }

    function openBoardById(boardId) {
        const board = getBoardById(boardId);
        if (!board)
            return console.log("Board not found with this id", { boardId });
        openBoard(board, false);
    }

    function openBoard(board, is_to_save_state = true) {
        if (!board?.board_id) return console.log("board is invalid to open");

        if (!Array.isArray(board.list)) board.list = [];

        activeBoard.value = board;
        loggedInUser()?.setOpenedBoardId(board.board_id);
        fetchList();
        openItemsBoardWithBoard();
        is_to_save_state && saveCurrentState();
    }

    function resetCurrentActiveBoard() {
        activeBoard.value = {};
        saveCurrentState();
        openItemsBoardWithWorkspace();
    }

    function currentActiveBoard() {
        return activeBoard.value;
    }

    function currentActiveBoardId() {
        return currentActiveBoard()?.board_id;
    }

    function currentActiveBoardCategory() {
        return currentActiveBoard()?.board_category;
    }

    function openItemsBoardWithWorkspaceList() {
        itemsBoardOpenWith.value = "workspace-list";
    }

    function openItemsBoardWithWorkspace() {
        itemsBoardOpenWith.value = "workspace";
        //console.log("itemsBoardWith - ", itemsBoardOpenWith.value )
    }

    function openItemsBoardWithBoard() {
        itemsBoardOpenWith.value = "board";
    }

    function prepareBreadCumbForBoardAndItem(boardId, itemId) {
        const board = getBoardById(boardId);
        const item = getItemById(itemId);
        let breadCrumb = workspace.value.workspace_name;

        board && (breadCrumb += ` > ${board.board_name}`);
        item && (breadCrumb += ` > ${item.list_item_name}`);
        return breadCrumb;
    }

    async function addClientBoardToWorkspace(board) {
        if (!board)
            return console.log("addClientBoardToWorkspace : empty board");
        workspace.value.boards.unshift({
            board_category: board.board_category,
            boards: [board],
        });

        updateBoardCategeorySequenceId();
    }

    function openFirstBoardByCategory(board_category) {
        if (!board_category)
            return console.log("Invalid board category", board_category);
        const boardCatgory = workspace.value.boards.find(
            (boardCategoryDetails) =>
                boardCategoryDetails.board_category == board_category
        );

        if (!boardCatgory?.boards?.length)
            return console.log(
                "not able to open first board in given category"
            );

        openBoard(boardCatgory.boards[0]);
        setSidePanelCurrentViewWithItems();
    }

    return {
        workspaceList: computed(() => workspaceList.value),
        papareBoards: computed(() => papareBoards.value),
        workspaceName: computed(() => workspaceName.value),
        workspaceId: computed(() => workspaceId.value),
        isAnyWorkspaceSelected: computed(() => isAnyWorkspaceSelected.value),
        workspace: computed(() => workspace.value),
        createBoardListName,
        updateBoardListName,
        archiveBoardListAtIndex,
        restoreBoardListAtIndex,
        //updateBoardCatergorySequenceIdForBoardsList,
        updateBoardSequenceIdForBoardsListAtIndex,
        keepLastBoardListBeforeFakeOneIfMovedAfterAndUpdateBoardCategorySequenceIdForBoardsListAt,
        fetchWorkspaces,
        fetchBoards,
        processMyWorkspaceBoards,
        processWorkspaceList,
        createBoard,
        updateBoard,
        archiveBoard,
        restoreBoard,

        activeBoard: computed(() => activeBoard.value),
        openBoard,
        openBoardById,
        currentActiveBoard,
        currentActiveBoardId,
        currentActiveBoardCategory,
        resetCurrentActiveBoard,

        isAnyBoardSelected: computed(() => activeBoard.value?.board_id),
        prepareMapForItemIdBoardAndList,
        getListById,
        removeListById,
        getItemById,
        getBoardById,
        getBoardAndItemByItemId,
        removeItemById,
        addListToBoardAndUpdateMap,
        addItemToListAndUpdateMap,
        setConverstionForItem,
        doesConversationBelongToItem,

        itemsBoardOpenWith: computed(() => itemsBoardOpenWith.value),
        openItemsBoardWithWorkspaceList,
        openItemsBoardWithWorkspace,
        openItemsBoardWithBoard,
        isItemsBoardOpenedWithWorkspaceList: computed(
            () => itemsBoardOpenWith.value == "workspace-list"
        ),
        isItemsBoardOpenedWithWorkspace: computed(
            () => itemsBoardOpenWith.value == "workspace"
        ),
        isItemsBoardOpenedWithBoard: computed(
            () => itemsBoardOpenWith.value == "board"
        ),

        sync_addBoard,
        sync_updateBoard,
        sync_removeOrRestoreBoard,
        sync_boardCategoryUpdated,
        sync_boardCategoryToggleDeletedAtForBoards,
        sync_updateWorkspaceBoardCategoriesReordering,
        sync_updateBoardCategoryBoardsOrdering,
        sync_updateBoardListOrdering,
        sync_updateListItemsOrdering,

        prepareBreadCumbForBoardAndItem,

        addClientBoardToWorkspace,
        openFirstBoardByCategory,
        //setItemsForChatAndEditorToRestoreAfterWorkspaceFetched
    };
}
