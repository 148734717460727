import {ref, computed} from 'vue'

import Cache from '../Utilities/Cache';
const cache = new Cache;
/* screen shot */
import conversationApi  from './conversations';
const { openConversationOnly  } = conversationApi()
import { resetListItemOpenedForEditing } from './list';

const hasScreenShotStarted = ref(false);
const isSC = ref(false);
const isSV = ref(false);
const isSF = ref(false);
const annotateOnImage = ref('')
const totalCrops = ref(0);
const scrollYPos = ref(0);
const isScreenShotToSendToChat = ref(false)

/* sidepanel */
const sidePanelTopCurrentView = ref("");
const isSidePanelOpened = ref(false)
const sidePanelCurrentView = ref('');
const sidePanelViewLastOpened = ref('');
const isForGroupInvitation = ref(false);
/* resize panel*/
var isPanelResizing = ref(false);

const isForItemMoveOperation = ref(false);
const isForItemCopyOperation = ref(false);
const isForItemOperation = ref(false);

let documentTitle = document.title;
const conversationUnreadMessgesCountMap = new Map();

function scrollBodyToTop(height = 0) {
    document.body.scrollTop = height; // For Safari
    document.documentElement.scrollTop = height; // For Chrome, Firefox, IE and Opera
}

const isSidePanelTopActiveViewActivity = computed(() => sidePanelTopCurrentView.value == 'activity');
const isSidePanelTopActiveViewWork = computed(() => sidePanelTopCurrentView.value == 'work');
const isSidePanelTopActiveViewQuote = computed(() => sidePanelTopCurrentView.value == 'quote');

const isSidePanelCurrentViewEditor = computed(() => sidePanelCurrentView.value == 'editor');
const isSidePanelCurrentViewItems = computed(() => sidePanelCurrentView.value == 'items');
const isSidePanelCurrentViewPeople = computed(() => sidePanelCurrentView.value == 'people')
const isSidePanelCurrentViewGroup = computed(() => sidePanelCurrentView.value == 'group')
const isSidePanelCurrentViewAll = computed( () => sidePanelCurrentView.value == 'all')

const activityTabPanelViews = ['all', 'people', 'group']
const workTabPanelViews = ['items', 'editor']

const totalUnreadMessagesCount = ref(0)

function updateLastOpenedViewWithCurrentForStateResoring() {
    sidePanelViewLastOpened.value = sidePanelCurrentView.value;
    cache.sidePanelOpenedWith(sidePanelCurrentView.value)
}

function getSidePanelCurrentview () {
    return  sidePanelCurrentView.value;
}

function setSidePanelCurrentViewWith(tabType) {
    const validTab = [...activityTabPanelViews, ...workTabPanelViews ].includes(tabType);
    if ( !validTab )
        return closeSidePanel()
    if( tabType != 'editor' )
        resetListItemOpenedForEditing();

    sidePanelCurrentView.value = tabType;
    //make top menu item active
    activityTabPanelViews.includes(tabType)
        ? setSidePanelTopActiveViewWithActivity()
        : setSidePanelTopActiveViewWithWork()

    updateLastOpenedViewWithCurrentForStateResoring();
}

function  setSidePanelWithAndToggle(type) {
    //close sidepanel if user Clicked 2nd time on Sidepanel menu items
    if( sidePanelCurrentView.value === type )
        return closeSidePanel()
    sidePanelCurrentView.value = type;
    updateLastOpenedViewWithCurrentForStateResoring();
}

function setSidePanelCurrentViewWithItemsAndToggle() {
    setSidePanelWithAndToggle('items');
}

function setSidePanelCurrentViewWithPeopleAndToggle() {
    setSidePanelWithAndToggle('people');
}

function setSidePanelTopActiveViewWithActivity() {
    sidePanelTopCurrentView.value = 'activity'
    //resetListItemOpenedForEditing();
}

function setSidePanelTopActiveViewWithWork() {
    sidePanelTopCurrentView.value = 'work'
    //resetListItemOpenedForEditing();
}

function setSidePanelTopActiveViewWithQuote() {
    sidePanelTopCurrentView.value = 'quote'
    sidePanelCurrentView.value = ""
}

function setSidePanelCurrentViewWithItems() {
    /* if (sidePanelViewLastOpened.value )
        sidePanelCurrentView.value = sidePanelViewLastOpened.value
    else { */
        sidePanelCurrentView.value = 'items';
        //setSidePanelTopActiveViewWithActivity()
        resetListItemOpenedForEditing();
    //}
    //console.log("current view: ", sidePanelCurrentView.value, sidePanelViewLastOpened.value);
    setSidePanelTopActiveViewWithWork()
    updateLastOpenedViewWithCurrentForStateResoring();
}

function setSidePanelCurrentViewWithEditor() {
    sidePanelCurrentView.value = 'editor';
    setSidePanelTopActiveViewWithWork()
    updateLastOpenedViewWithCurrentForStateResoring();
}

function setSidePanelCurrentViewWithPeople() {
    sidePanelCurrentView.value = 'people';
    setSidePanelTopActiveViewWithActivity()
    updateLastOpenedViewWithCurrentForStateResoring();
}

function setSidePanelCurrentViewWithGroup() {
    sidePanelCurrentView.value = 'group';
    setSidePanelTopActiveViewWithActivity()
    updateLastOpenedViewWithCurrentForStateResoring();
}


function setSidePanelCurrentViewWithGroupForInvitation(isGroupInvitation = true) {
    isForGroupInvitation.value = isGroupInvitation;
}

function setSidePanelCurrentViewWithAll() {
    sidePanelCurrentView.value = 'all';
    setSidePanelTopActiveViewWithActivity();
    updateLastOpenedViewWithCurrentForStateResoring();
}

function restoreSidePanelViewWithLastOpened() {
    sidePanelCurrentView.value = sidePanelViewLastOpened.value;
}

function closeSidePanel() {
    isSidePanelOpened.value = false
    //updateLastOpenedViewWithCurrentForStateResoring();
    //resetListItemOpenedForEditing();
    cache.setSidePanelState(0)
    //console.trace("ClosingSidepanel");
    //sidePanelCurrentView.value = '';
}

function openSidePanel() {
    isSidePanelOpened.value = true
    cache.setSidePanelState(1);
}

function disableBodyScroll() {
    document.querySelector("body").style.setProperty('overflow', 'hidden');
}

function disableBodyHScroll() {
    const body = document.querySelector("body");
    body.style.setProperty('overflow-x', 'hidden');
    body.style.setProperty('display', 'block');
}

function disableBodyVScroll() {
    document.querySelector("body").style.setProperty('overflow-y', 'hidden');
}

function enableBodyScroll() {
    const body = document.querySelector("body")
    body.style.setProperty('overflow', 'visible')
}

function getAdminHandle() {
    return document.getElementById('wpadminbar')
}
function hideAdminBar() {
    const adminBarElement = getAdminHandle();
    adminBarElement?.style.setProperty('z-index', '99')
}

function showAdminBar() {
    const adminBarElement = getAdminHandle();
    adminBarElement?.style.setProperty('z-index', null)
}

function calculateAdminBarHeight() {
    const rect = getAdminHandle()?.getBoundingClientRect();
    return Math.min(window.pageYOffset, rect.height);
}

function calculateTotalPageWidth() {
    return Math.max(
        document.body.scrollWidth, document.documentElement.scrollWidth,
        document.body.offsetWidth, document.documentElement.offsetWidth,
        document.body.clientWidth, document.documentElement.clientWidth
    );
}

function calculateTotalPageHeight() {
    return Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
}

function  calculateViewablePageWidth() {
    return document.documentElement.clientWidth
}

function calculateViewablePageHeight() {
    return document.documentElement.clientHeight
}

function storeBodyScrollPositionForRestoringIfActionIsSF() {
    if (!isSF.value)
        return;
    scrollYPos.value = window.pageYOffset;
    scrollBodyToTop(0);
}

function restoreBodyScrollPositionIfLastActionWasSF() {
    if (!isSF.value)
        return;
    scrollBodyToTop(scrollYPos.value);
    scrollYPos.value = 0;
}

function resetAllScreenShotRelatedValues() {
    restoreBodyScrollPositionIfLastActionWasSF();
    enableBodyScroll();
    isScreenShotToSendToChat.value = false;
    hasScreenShotStarted.value = false;
    isSF.value = isSV.value = isSC.value  = false;
    annotateOnImage.value= '';
}

function restoreConversationIfOpenedBeforeStratingAnyScreenShotAction() {
    if( isScreenShotToSendToChat.value )
        openConversationOnly();
}

function openScreenShot(type, isToChat) {
    isSC.value = type === 'SC';
    isSV.value = type === 'SV';
    isSF.value = type === 'SF';
    storeBodyScrollPositionForRestoringIfActionIsSF()
    isScreenShotToSendToChat.value = isToChat;
    hasScreenShotStarted.value = true;
}

function openImageForAnnotation(imgUrl, isToChat = true) {
    // image can be click
    annotateOnImage.value = imgUrl;
    isScreenShotToSendToChat.value = isToChat;
    hasScreenShotStarted.value = true;
}

function cancelScreenShot() {
    restoreSidePanelViewWithLastOpened()
    restoreConversationIfOpenedBeforeStratingAnyScreenShotAction()
    resetAllScreenShotRelatedValues();
}

function closeScreenShot() {
    resetAllScreenShotRelatedValues()
}

function incrementTotalCrops() {
    totalCrops.value +=1;
}

function kaamlyCreateAccountUrl() {
    return `${prospus_data['kaamly_base_url']}/wp-connect?wp_domain=${window.location.href}`;
}

function setPanelResizing(targetStatus) {
    isPanelResizing.value = targetStatus;
}

function logoUrl() {
    return prospus_data['logo']
}

function logoInvertedUrl() {
    return prospus_data['logo_inverted']
}

function logoMenu() {
    return prospus_data['logo_menu']
}

function logoMenuInverted() {
    return prospus_data['logo_menu_inverted']
}

function logoLogin() {
    return prospus_data['logo_login']
}
function placeHolderSmall() {
    return prospus_data['placeholder_small'];
}

function setItemOperation(operationName = 'move') {

    isForItemOperation.value = true;
    if (operationName == 'move') {
        isForItemCopyOperation.value = false;
        isForItemMoveOperation.value = true;
    }
    if (operationName == 'copy') {
        isForItemCopyOperation.value = true;
        isForItemMoveOperation.value = false;
    }

}
/* singleConvCount = +ve or -ve */
function updateUnreadMessagesCountBy(sid, count) {

    if( count == null )
        return;
    documentTitle ? '' : (documentTitle = document.title);

    conversationUnreadMessgesCountMap.set(sid, count);

    let unreadMessagesCount = 0;
    for (const [key, value] of conversationUnreadMessgesCountMap) {
        unreadMessagesCount += (value == null ? 0 : value);
    }
    const finalCount = unreadMessagesCount <= 0 ? '' : `(${unreadMessagesCount}) `;
    document.title =  `${finalCount}${documentTitle}`
    totalUnreadMessagesCount.value = unreadMessagesCount;
}


function closeItemOperation() {
    if (isForItemOperation.value == true) {
        isForItemOperation.value = false;
        isForItemCopyOperation.value = false;
        isForItemMoveOperation.value = false;
    }
}


/* to preserve from where editor was opened Boards/Activity */
const editorOpenedFrom = ref('');

const isEditorOpenedFromActivity = computed(() => editorOpenedFrom.value == 'activity')

function editorOpenedFromActivity() {
    editorOpenedFrom.value = 'activity';
}

function editorOpenedFromBoard() {
    editorOpenedFrom.value = 'board';
}

export {
    sidePanelCurrentView,
    sidePanelTopCurrentView,
    setSidePanelTopActiveViewWithActivity,
    setSidePanelTopActiveViewWithWork,
    setSidePanelTopActiveViewWithQuote,
    setSidePanelCurrentViewWith,
    setSidePanelCurrentViewWithItems,
    setSidePanelCurrentViewWithItemsAndToggle,
    setSidePanelCurrentViewWithEditor,
    setSidePanelCurrentViewWithPeople,
    setSidePanelCurrentViewWithGroup,
    setSidePanelCurrentViewWithAll,
    setSidePanelCurrentViewWithPeopleAndToggle,
    restoreSidePanelViewWithLastOpened,

    setSidePanelCurrentViewWithGroupForInvitation,
    isForGroupInvitation,

    closeSidePanel,
    openSidePanel,
    isSidePanelOpened,
    getSidePanelCurrentview,
    isSidePanelTopActiveViewActivity,
    isSidePanelTopActiveViewWork,
    isSidePanelTopActiveViewQuote,
    isSidePanelCurrentViewEditor,
    isSidePanelCurrentViewItems,
    isSidePanelCurrentViewPeople,
    isSidePanelCurrentViewGroup,
    isSidePanelCurrentViewAll,

    disableBodyScroll,
    disableBodyHScroll,
    enableBodyScroll,
    scrollBodyToTop,

    hideAdminBar,
    showAdminBar,

    calculateAdminBarHeight,
    calculateTotalPageHeight,
    calculateTotalPageWidth,
    calculateViewablePageHeight,
    calculateViewablePageWidth,

    isScreenShotToSendToChat,
    hasScreenShotStarted,
    isSF, isSV, isSC, annotateOnImage,
    openImageForAnnotation,
    openScreenShot,
    closeScreenShot,
    cancelScreenShot,
    totalCrops,
    incrementTotalCrops,

    kaamlyCreateAccountUrl,

    isPanelResizing,
    setPanelResizing,

    logoUrl,
    logoInvertedUrl,
    logoMenu,
    logoMenuInverted,
    logoLogin,
    placeHolderSmall,
    isForItemMoveOperation,
    isForItemCopyOperation,
    isForItemOperation,
    setItemOperation,
    closeItemOperation,

    updateUnreadMessagesCountBy,
    totalUnreadMessagesCount,

    isEditorOpenedFromActivity,
    editorOpenedFromActivity,
    editorOpenedFromBoard
}
