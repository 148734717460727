import axios from "axios"
import {defineStore} from "pinia"
import {useAppState} from "../Stores/AppState"
import {useWorkspace} from "../Stores/Workspaces"

import TwilioFactory from '../Utilities/TwilioClientObserver'
import {useConversation} from "../Stores/Conversation"
import {cloneDeep} from 'lodash'
import {de} from "vuetify/locale";
import {useTeamPanel} from "./TeamPanel";

export const useUserStore = defineStore('UserStore', {
    state: () => ({
        user: {
            id: '',
            name: '',
            email: '',
        },
        profile: {
            id: '',
            name: '',
            email: '',
        },
        user_data: null,
        newInvitedMember: false,
        newAssignMember: false,
        users: [],
        own_profiles: [],
        profiles: [],
        assigned_users: null,
        user_search_key: null,
        default_avatar: "/images/default-avatar.png",
        is_reinitialize_started: false
    }),
    getters: {
        is_client: (state) => state.profile?.type === 'Client',
        is_not_client() {
            return !this.is_client
        },
        getTwilioIdentity: (state) => state.user?.twilio_identity
    },
    actions: {
        filterProfilesBy(search_key = '', profile_ids_at_start = []) {
            let members = this.profiles
            if (search_key)
                members = members.filter(member =>
                    member?.full_name?.toLowerCase().indexOf(search_key.toLowerCase()) > -1 ||
                    member?.email?.toLowerCase().indexOf(search_key.toLowerCase()) > -1
                );

            if (!profile_ids_at_start?.length)
                return members

            profile_ids_at_start
                .filter(profile_id => parseInt(profile_id))
                ?.forEach(profile_id => {
                    const workspace_onwer_index = members.findIndex(profile => profile_id == profile.id)
                    if (workspace_onwer_index > 0) {
                        const owner_profile = members.splice(workspace_onwer_index, 1)[0]
                        members.unshift(owner_profile)
                    }
                })

            return members
        },
        setUserSearchKey(key = '') {
            this.user_search_key = key
        },
        async initialize(is_reinitialize = false) {
            this.createTwilioClientIfUserAlreadyLoggedin()
            const workspace_store = useWorkspace()
            const conversation_store = useConversation()
            const app_state_store = useAppState()
            Promise.all([
                axios.get('/user'), // this should fetch user statte too
                axios.get(workspace_store.space_categories_url),
                axios.get("/conversations"),
            ])
                .then(async ([userData, spacesData, convData]) => {
                    //window.logTimeWith(" processing boards ");
                    const {profile, user, state} = userData.data
                    this.setUpUserData(user, profile)

                    if (this.is_not_client) {
                        const conversations = convData.data?.conversations.map(conversation => {
                            conversation.is_quick_load = state.conversation_id === conversation.id;
                            return conversation;
                        });

                        conversation_store.initialize(conversations, is_reinitialize)
                    }

                    this.setDefaultViewForClients()

                    workspace_store.setSpaces(spacesData.data)

                    app_state_store.initializeWith(state)
                    app_state_store.fetchLoggedTime();

                }).catch(error => {
                console.trace("Error on initialize")
                if (error?.response?.status === 403)
                    app_state_store.setPermissionError(true)
            })
        },

        setUpUserData(user, profile) {

            this.user = user
            this.profile = profile
            window.user = this.user
            window.profile = this.profile
            localStorage.setItem('kaamfu_user_id', this.user.id)
            this.createTwilioClient();

            window.registerForLiveSyncOnProfile(window.profile.id, window.profile.organization_id)
        },
        setUpUserProfileData(profile) {

            this.profile = profile
            window.profile = this.profile
            localStorage.setItem('kaamfu_profile_id', this.profile.id)

            this.createTwilioClient();
        },
        setUpconversations(conversations) {
            initializeConversations()
        },
        createTwilioClient() {
            if (!this.profile.twilio_identity)
                return console.log("no twilio identity created")

            const {subscribeWithIdentity} = TwilioFactory()
            subscribeWithIdentity(this.profile.twilio_identity)
        },
        /* this is to pre initialize twilio client before loading any data
        as so speed up loading conversations */
        createTwilioClientIfUserAlreadyLoggedin() {
            const logged_in_user_id = localStorage.getItem('kaamfu_user_id')
            if (!logged_in_user_id)
                return;
            const {subscribeWithIdentity} = TwilioFactory()
            subscribeWithIdentity(logged_in_user_id)

        },
        async fetchUsers() {
            if (this.users?.length)
                return this.users

            return axios
                .get('/users')
                .then(({data}) => {
                    this.users = data
                    return data
                })
        },
        async fetchUsersProfiles() {
            if (this.profiles?.length)
                return this.profiles

            return axios
                .get('/user-profiles')
                .then(({data}) => {
                    this.profiles = data

                    return data
                })
        },
        setNewInvitedMember(val) {
            this.newAssignMember = false;
            this.newInvitedMember = val;
        },
        setNewAssignMember(val) {
            this.newInvitedMember = false;
            this.newAssignMember = val;
        },
        setAssignedUsers() {
            this.assigned_users = cloneDeep(this.users)
            this.assigned_users = this.assigned_users.map(user => {
                if (this.isUserParticipant(user))
                    user.is_participant = true
                return user
            })
        },
        isUserParticipant(user) {
            const conversation_store = useConversation()
            const participants = conversation_store?.active?.participants

            return participants?.findIndex(participant => participant.id == user.id) >= 0
        },
        isCurrentProfile(profile_id) {
            return this.profile.id === profile_id;
        },
        isAuthorizedFor(permission) {
            return Boolean(this.profile?.permissions?.[permission])
        },
        getUserProfile(profile_id) {
            return this.profiles?.find(profile => profile.id == profile_id)
        },
        getCurrentUserProfile() {
            return this.profile;
        },
        getCurrentUserProfileId() {
            return this.profile?.id;
        },
        userProfileSetActiveTask(profile_id, status) {
            return;
            const profile = this.profiles?.find(profile => profile.id == profile_id)
            profile.active_task_name = status
        },
        updateAccount() {
            axios.patch('/user', {first_name: this.user.first_name, last_name: this.user.last_name})
        },
        updateProfile(profile) {
            axios.patch(`/profiles/${profile.id}`, profile).then(({data}) => {

            });
        },
        updateProfileAvatar(profileId, base64) {
            axios.post(`/profiles/${profileId}/avatar`, {avatar: base64})
                .then(({data}) => {
                    if (profile.id === profileId) {
                        profile.avatar_url = data.data.url;
                    }

                });
        },
        setUserData(val) {
            this.user_data = val
            this.own_profiles = val.profiles
        },
        async getProfileById(profile_id) {
            try {
                const {data} = await axios.get(`user-profiles/${profile_id}`);
                return data.data;
            } catch (error) {
                console.error('getProfileById failed', error)
                return {};
            }
        },
        async replaceProfile(new_profile) {
            //const profile_index = this.profiles.findIndex(profile => profile.id === new_profile.id)
            const profile_index = this.profiles.findIndex(profile => profile.email === new_profile.email)
            const profile = await this.getProfileById(new_profile.id);
            profile.is_login = 0;
            if (this.isCurrentProfile(profile.id))
                profile.is_login = 1;

            if (profile_index >= 0) {
                // merge and replace profile.
                this.profiles.splice(profile_index, 1, {...this.profiles[profile_index], ...profile})
            }
        },
        addProfile(new_profile) {
            const profile_index = this.profiles.findIndex(profile => profile.email === new_profile.email)
            if (profile_index !== -1) {
                this.profiles.splice(profile_index, 1, new_profile)
            } else {
                this.profiles.push(new_profile)
            }
        },
        updateProfileTask(item) {
            const active_task_profile = this.profiles.find(profile => profile?.active_task?.id === item.id)
            if (active_task_profile)
                active_task_profile.active_task.name = item.name;

            const paused_task_profile = this.profiles.find(profile => profile.paused_task?.id === item.id)
            if (paused_task_profile)
                paused_task_profile.paused_task.name = item.name;
        },
        setDefaultViewForClients() {
            if (this.is_not_client)
                return;

            const workspace_store = useWorkspace()
            workspace_store.setFullMode()
        },

        isCurrentUser(twilio_identity) {
            return this.user.twilio_identity === twilio_identity
        },

        isNotCurrentUser(twilio_identity) {
            return this.user.twilio_identity !== twilio_identity
        },

        async reInitialize() {
            if (this.is_reinitialize_started) {
                return;
            }
            try {
                this.is_reinitialize_started = true;
                console.log('ReInitialize started at', Date().toString())
                this.initialize(true);
                // Team Panel start
                if (useTeamPanel().is_prepared) {
                    await useTeamPanel().reInitializeTeamPanel();
                }
                console.log('ReInitialize ended at', Date().toString())
                this.is_reinitialize_started = false;
                // Team Panel end

            } catch (e) {
                console.log('ReInitialize failed', e, Date().toString())
                this.is_reinitialize_started = false;
            }
        },

        increaseTwilioConversationCount() {
            this.profile.twilio_conversations_count += 1;
        },
        setTwilioConversationCount(count) {
            this.profile.twilio_conversations_count = count;
        },
        decreaseTwilioConversationCount() {
            this.profile.twilio_conversations_count -= 1;
        },
        canInviteToOrganization(){
            return this.getCurrentUserProfile().type === 'business';
        }
    }
})
