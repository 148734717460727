import axios from 'axios'
import {defineStore} from 'pinia'
import {useWorkspace} from "./Workspaces";
import {useUserStore} from "./User";
import Utilities from '../Helpers/utilities'
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import {useConversation} from "./Conversation";
import {useAppState} from "./AppState";
import utilities from "../Helpers/utilities";
import DateUtilities from "../Utilities/DateUtilities";

const DEFAULT_GROUP = 'Backlog'
export const useTeamPanel = defineStore('TeamPanelStore', {
    state: () => ({
        team_panel_title: DEFAULT_GROUP,
        user_task_groups: [],
        selected_member: {},
        opened_member: {},
        selected_subordinate: {},
        is_dragged_target_category: null,
        team_panel_data: [],
        team_panel_data_prepared: false,
        team_panel_subordinates: false,
        pinned_team_member: null,
        new_current_user: null,
        screenshots: null,
        subordinates: [],
        default_group: DEFAULT_GROUP,
        has_team_active_item: false,
        team_filter: [
            {key: 'shift-in', text: 'Shifted In', checked: true},
            {key: 'shift-out', text: 'Shifted Out', checked: true}
        ],
        active_tab: 'backlog'
    }),
    getters: {
        profiles_with_tasks: (state) => state.team_panel_data.filter(profile => {
            const current_user_subordinate = state.subordinates.find(subordinate => subordinate.assigner_id === state.getCurrentUserProfileId());
            // make sure current user has assigned any task to new profile or ( its own profile and it has active task )
            return (profile.user_profile_id === state.getCurrentUserProfileId()
                ||
                (
                    current_user_subordinate?.assignees?.includes(profile.user_profile_id)
                    && state.hasProfileActiveTask(profile.user_profile_id, state.getCurrentUserProfileId())
                )
            );
        }),
        user_default_group: (state) => state.default_group,
        is_prepared: (state) => state.team_panel_data_prepared
    },
    actions: {
        async reInitializeTeamPanel() {
            this.setTeamPanelDatePrepared(false);
            await this.initializeTeamPanel();
        },
        async initializeTeamPanel() {
            if (this.team_panel_data_prepared)
                return;


            await this.fetchSubordinates();
            await axios
                .get('/user-profile-tasks/group')
                .then(({data}) => {
                    this.user_task_groups = data
                    this.prepareTeamPanel()
                    this.initializeTeamFilter()
                    return data
                })
        },
        getCurrentUserProfileId() {

            if (this.team_panel_subordinates)
                return this.new_current_user;

            return useUserStore().profile.id;
        },
        getCurrentProfile() {
            return useUserStore().profile;
        },
        getAllProfiles() {
            return useUserStore().profiles;
        },
        getFilterProfile() {
            return isEmpty(this.selected_subordinate) ? this.selected_member : this.selected_subordinate;
        },
        getFilterAssigner() {
            return isEmpty(this.selected_subordinate) ? this.byUserProfileTask(this.getCurrentUserProfileId()) : this.selected_member;
        },
        getAssignersByProfile(assignee_profile_id) {
            const assigners = new Set();
            this.team_panel_data.find(profile => profile.user_profile_id === assignee_profile_id)?.data?.forEach(group => group?.items.forEach(task_item => {
                if (task_item?.item?.version?.user_profile_id_creator)
                    assigners.add(task_item?.item?.version?.user_profile_id_creator);
            }));
            return assigners;
        },
        async fetchSubordinates() {
            return axios
                .get('/user-profile-tasks/subordinates')
                .then(({data}) => {
                    this.subordinates = data.data
                    return data.data
                })
        },
        pushProfileToTeamPanel(profile_to_add) {
            const user_profile_index = this.team_panel_data.findIndex(user_profile => user_profile.user_profile_id === profile_to_add.user_profile_id)
            if (user_profile_index < 0) {
                this.team_panel_data.push(profile_to_add);
                return;
            }
            this.team_panel_data.splice(user_profile_index, 1, profile_to_add);
            return;

            const current_user_subordinate = this.subordinates.find(subordinate => subordinate.assigner_id === this.getCurrentUserProfileId());
            // make sure current user has assigned any task to new profile or its own profile.
            if (current_user_subordinate?.assignees?.includes(profileToAdd.user_profile_id)
                || profileToAdd.user_profile_id === this.getCurrentUserProfileId()) {
                this.team_panel_data.push(profileToAdd);
            }
        },
        setPinnedTeamMember(member) {
            this.pinned_team_member = member
        },
        setSelectedTeamMemberById(profile_id) {
            const profile = this.getAllProfiles().find(profile => profile.user_profile_id === profile_id);

            useWorkspace().closeEditor(false);
            useWorkspace().setActiveItemEmpty();

            if (!isEmpty(profile))
                this.setSelectedTeamMember(profile);
        },
        selectWorkingTask(profile_id) {
            const profile = this.getAllProfiles().find(profile => profile.user_profile_id === profile_id);
            const item = profile?.active_task || profile?.paused_task;
            if (item) {

                useWorkspace().setActiveItemById(item.id);
                if (useAppState().is_conversation_open) {
                    useConversation().openConversationForItem(item)
                }

                const assigner_id = item.version?.user_profile_id_creator;

                if (!useUserStore().isCurrentProfile(assigner_id)
                    && this.profiles_with_tasks.findIndex(profile => profile.user_profile_id === assigner_id) !== -1
                    && !this.isSelfAssigned(assigner_id, item)
                ) {
                    const assigner = this.getAllProfiles().find(profile => profile.user_profile_id === assigner_id);
                    this.setSelectedTeamMember(assigner, profile);
                }
            }

            if (!profile?.active_task)
                utilities().showInfoSnackbar(`<b>${profile?.display_name || profile?.full_name}</b> has not started a task.`, profile?.avatar_url)

        },
        setSelectedTeamMember(member, subordinate = {}) {
            this.selected_member = member
            this.selected_subordinate = subordinate
            this.setTeamPanelTitle();
            this.setSelectedMemberTasks();
        },
        onEmptySelectedMemberList() {
            // set own user as a selected.
            this.setSelectedTeamMember(this.profiles_with_tasks[0]);
            useWorkspace().setActiveItemEmpty();

        },
        setTeamPanelTitle() {
            let selected_member_title = "";
            let subordinate_title = "";

            if (!isEmpty(this.selected_member))
                selected_member_title = this.selected_member?.full_name || this.selected_member?.email;

            if (!isEmpty(this.selected_subordinate))
                subordinate_title += this.selected_subordinate?.full_name || this.selected_subordinate?.email;

            if (selected_member_title && subordinate_title) {
                this.team_panel_title = `${selected_member_title} > ${subordinate_title}`
                return;
            }

            this.team_panel_title = selected_member_title;
        },

        setSelectedMemberTasks() {
            // retrieve whose data want to see
            let profile = this.getFilterProfile();

            // face only in local. if no selected member return.
            if (isEmpty(profile))
                return;

            // retrieve who assigned data to profile.
            let assigner = this.getFilterAssigner();

            const current_task_profile = this.byUserProfileTask(profile.user_profile_id);
            const task_profile = cloneDeep(current_task_profile)


            if (profile.user_profile_id !== this.getCurrentUserProfileId()
                || !isEmpty(this.selected_subordinate)) {
                task_profile?.data.map(task_group => {
                    task_group.items = task_group?.items.filter(task_item => {
                        const task_assigner = task_item?.item?.version?.user_profile_id_creator;

                        // if its drill-down than only show assigned by assignee
                        // if subordinate is selected only show assigned by assignee

                        if ((this.team_panel_subordinates && profile.user_profile_id !== assigner.user_profile_id)
                            || (!this.team_panel_subordinates && !isEmpty(this.selected_subordinate)))
                            return task_assigner === assigner.user_profile_id


                        return task_assigner === assigner.user_profile_id
                            || task_assigner === profile.user_profile_id
                    })
                    return task_group;
                })
            }

            this.selected_member.tasks = task_profile;

            useAppState().updateTeam({
                    'selected_user_id': this.selected_member?.id,
                    'assigner_id': assigner?.user_profile_id,
                    'is_subordinate_panel': this.team_panel_subordinates,
                    'selected_subordinate_id': this.selected_subordinate?.user_profile_id || false
                }
            );
        },
        byUserProfileTask(user_profile_id) {
            return this.team_panel_data.find((task) => task.user_profile_id == user_profile_id)
        },
        findItem(item_id) {
            return this.selected_member.tasks.data.find(v => v.item_ids.includes(item_id))
        },
        findItemProfileTasks(item_id) {
            return this.team_panel_data.find((task_group) => {
                return task_group.data.find((task) => {
                    return task?.item_ids?.includes(item_id)
                })
            })
        },
        findItemByUserProfileTasks(item_id, user_profile_id) {
            return this.team_panel_data.find((task_group) => {
                return task_group.data.find((task) => {
                    return task.user_profile_id == user_profile_id && task?.item_ids?.includes(item_id)
                })
            })
        },
        byUserProfileAssignedTaskGroup(user_profile_id) {
            return this.team_panel_data.find(task_group => task_group.user_profile_id == user_profile_id)?.data?.find(task => task.name == 'Assigned')
        },
        removeItemFromTaskGroup(item_id) {
            const findItemTaskGroups = this.findItemProfileTasks(item_id)

            if (findItemTaskGroups?.data.length == 0)
                return

            findItemTaskGroups?.data.forEach((assignedGroup, index) => {
                this.removeItemFromUserTaskItemGroup(assignedGroup, item_id)
            })

            if (findItemTaskGroups?.data?.filter(task_group => task_group?.items.filter(task => task).length).length == 0 && findItemTaskGroups.user_profile_id != this.getCurrentUserProfileId())
                this.selected_member = this.profiles_with_tasks[0]

        },
        addItemToNewTaskGroup(profile_id, responce) {
            const assign_responce = responce

            assign_responce.data.items = []
            assign_responce.data.item_ids.forEach(item_id => {
                const findItem = useWorkspace().getSpaceBoardForItem(item_id)
                if (findItem.item) {
                    assign_responce.data.items.push(useWorkspace().getSpaceBoardForItem(item_id))
                }
            })

            const find_own_profile_team_panel_data = this.team_panel_data.find(profile => profile.user_profile_id == profile_id)

            if (find_own_profile_team_panel_data)
                return find_own_profile_team_panel_data.data.push(assign_responce.data);

            const assigned_user_profile = useUserStore().profiles.find(profile => profile.id == profile_id)
            const profileData = this.prepareProfileData(assigned_user_profile);
            profileData?.data?.push(assign_responce.data)
            this.pushProfileToTeamPanel(profileData)
            //this.setSubordinates(profileData.user_profile_id)
        },
        addItemToProfileTaskGroup(item_id, profile_id, responce) {
            if (this.team_panel_data.length == 0)
                return;

            this.removeItemFromTaskGroup(item_id)

            const findAssignedGroup = this.byUserProfileAssignedTaskGroup(profile_id)

            if (findAssignedGroup) {
                findAssignedGroup.item_ids.push(item_id)
                findAssignedGroup.items.push(useWorkspace().getSpaceBoardForItem(item_id))
                return
            }

            this.addItemToNewTaskGroup(profile_id, responce)
        },
        removeItemFromUserTaskItemGroup(user_task_group, item_id) {

            const assigned_group_item_id_index = user_task_group?.item_ids?.findIndex((task_item_id) => task_item_id == item_id)
            if (assigned_group_item_id_index >= 0) {
                user_task_group?.item_ids.splice(assigned_group_item_id_index, 1)
            }

            const assigned_group_item_index = user_task_group?.items?.findIndex((item) => item?.item?.id == item_id)
            if (assigned_group_item_index >= 0) {
                user_task_group?.items.splice(assigned_group_item_index, 1)
            }

        },

        unassignItemToProfileTaskGroup(item_id, profile_id) {
            const findItemTaskGroups = this.findItemByUserProfileTasks(item_id, profile_id)

            if (findItemTaskGroups?.data.length > 0) {
                // const assignedGroup = findItemTaskGroups.data[0]
                this.removeItemFromUserTaskItemGroup(findItemTaskGroups.data[0], item_id)
            }

            if (findItemTaskGroups?.data?.filter(task_group => task_group?.items.filter(task => task).length).length == 0 && findItemTaskGroups.user_profile_id != this.getCurrentUserProfileId())
                this.selected_member = this.profiles_with_tasks[0]

        },
        removeItemFomTaskGroups(item_id) {
            const findAssignedGroup = this.findItemProfileTasks(item_id)
            if (findAssignedGroup?.data.length > 0) {
                findAssignedGroup.data.forEach(group => this.removeItemFromUserTaskItemGroup(group, item_id))
            }
        },
        addItemToTaskGroup(user_profile_id, item, data) {

            if (!data?.user_profile_id)
                return

            this.getUserTasks();

            if (data.action == 'assign')
                this.addItemToProfileTaskGroup(item?.id, user_profile_id, data)

            if (data.action == 'un-assign')
                this.unassignItemToProfileTaskGroup(item?.id, user_profile_id, data)

        },
        addLoginProfileTeamPanelData() {
            const profileData = {
                ...this.prepareProfileData(this.getCurrentProfile()),
                tasks: [],
                is_login: true
            }
            this.pushProfileToTeamPanel(profileData)
            //this.setSubordinates(profileData.user_prosfile_id)
        },
        getProfileActiveTask(user_profile_id) {
            const profile = this.getAllProfiles().find(profile => profile.id == user_profile_id)
            if (profile?.active_task)
                return profile?.active_task

            return null;

        },
        getProfilePausedTask(user_profile_id) {
            const profile = this.getAllProfiles().find(profile => profile.id == user_profile_id)
            if (profile?.paused_task)
                return profile?.paused_task

            return null;
        },
        getProfileActiveTaskItem(user_profile_id) {
            const profile = this.getAllProfiles().find(profile => profile.id == user_profile_id)
            if (profile?.active_task)
                return profile.active_task

            return null;

        },
        getSubordinatesByProfile(profile_id) {
            const profile = this.subordinates.find(subordinate => subordinate.assigner_id === profile_id)

            const profiles = [];

            profile?.assignees?.forEach(assignee => {
                const profile = this.getAllProfiles().find(profile => profile.id == assignee)
                if (profile && profile.user_profile_id !== profile_id)
                    profiles.push(profile);
            })

            return profiles;
        },
        setSubordinates(profile_id) {
            //can be useful in future to calculate subordinate without calling api
            return;
            const task_profile = this.byUserProfileTask(profile_id)
            const subordinate_ids = new Set();
            const subordinates = [];

            task_profile.data.forEach(task_group => {
                task_group.items.forEach(task => {
                    if (task?.version?.user_profile_id_creator)
                        subordinate_ids.add(task.version.user_profile_id_creator)
                });
            })

            subordinate_ids.forEach(assigner_profile_id => {
                const user_profile = this.getAllProfiles().find(profile => profile.id == task_profile.user_profile_id);

                if (user_profile)
                    subordinates.push(user_profile);
            });

            task_profile.subordinates = subordinates;
        },
        prepareProfileData(profile, data = []) {
            if (!profile?.id)
                return {}
            return {
                id: profile.id,
                full_name: profile.full_name,
                email: profile.email,
                avatar_url: profile.avatar_url,
                user_profile_id: profile.id,
                data
            };
        },

        prepareTeamPanel() {
            //if (!this.team_panel_data_prepared)
            this.addLoginProfileTeamPanelData()


            const task_group_data = Object.values(this.user_task_groups.groupBy('user_profile_id'));

            task_group_data.forEach((filtered_task_group, index) => {

                if (filtered_task_group.length == 0)
                    return

                //if (filtered_task_group.length > 0) {
                const user_profile = this.getAllProfiles().find(up => up.id == filtered_task_group[0]?.user_profile_id)
                if (!user_profile?.id)
                    return

                const find_own_profile_team_panel_data = this.team_panel_data.find(profile => profile.user_profile_id == user_profile.id)
                if (find_own_profile_team_panel_data)
                    return find_own_profile_team_panel_data.data = filtered_task_group;

                const profileData = this.prepareProfileData(user_profile, filtered_task_group);
                this.pushProfileToTeamPanel(profileData)
                // this.setSubordinates(profileData.user_profile_id)
                //    }

            })


            this.restoreLastSelection();
            this.reorderTeamPanel();
            this.team_panel_data_prepared = true
        },
        restoreLastSelection() {
            if (!this.profiles_with_tasks.length)
                return;


            const team_selection = useAppState().team;
            if (team_selection?.selected_user_id) {
                const profile = this.getAllProfiles().find(profile => profile.user_profile_id === team_selection?.selected_user_id);
                let subordinate = {};

                if (team_selection?.selected_subordinate_id)
                    subordinate = this.getAllProfiles().find(profile => profile.user_profile_id === team_selection?.selected_subordinate_id);

                if (team_selection?.is_subordinate_panel && team_selection?.assigner_id) {
                    const assigner = this.getAllProfiles().find(profile => profile.user_profile_id === team_selection?.assigner_id);
                    this.onDrillDown(assigner)
                    this.setSelectedTeamMember(profile);
                    return;
                }

                this.setSelectedTeamMember(profile, subordinate);
                return;
            }
            this.setSelectedTeamMember(this.profiles_with_tasks[0]);
        },

        reorderTeamPanel() {
            const current_profile_id = this.getCurrentUserProfileId();
            const my_assigners = this.getAssignersByProfile(current_profile_id)

            const team_panel_data = this.team_panel_data.map(profile => {
                if (profile.user_profile_id === current_profile_id)
                    profile.order = 1
                else if (my_assigners.has(profile.user_profile_id))
                    profile.order = 2
                else
                    profile.order = 3

                return profile;
            })

            team_panel_data.sort(function (a, b) {
                return a.order - b.order;
            });

            this.team_panel_data = team_panel_data;
        },
        initializeTeamFilter() {
            const current_filter = useAppState().team_filter;

            if (current_filter === 'all' || !current_filter)
                return

            this.team_filter = this.team_filter.map(filter => {
                filter.checked = false;

                if (filter.key === current_filter)
                    filter.checked = true

                return filter;
            });
        },
        toggleSubordinates(value = null) {
            if (value === null)
                return this.team_panel_subordinates = !this.team_panel_subordinates

            this.team_panel_subordinates = value
        },
        updateCurrentSpaceWithNew(space) {
            this.team_panel_data.filter(profile => profile?.data?.filter(task_group => task_group?.items?.filter(task_item => {
                if (task_item.space_id === space.id) {
                    task_item.space_name = space?.name
                    this.updateKeyForSourceAndDestination(task_item)
                }
            })))
            this.filterDataOnLiveSync();
        },
        updateCurrentBoardWithNew(board) {
            this.team_panel_data?.filter(profile => profile?.data?.filter(task_group => task_group?.items?.filter(task_item => {
                if (task_item.board_id == board.id) {
                    task_item.board_name = board?.name

                    this.updateKeyForSourceAndDestination(task_item)
                }
            })))
            this.filterDataOnLiveSync();
        },
        updateItemNameByItemId(item) {
            this.team_panel_data.filter(profile => profile?.data?.filter(task_group => task_group?.items?.filter(task_item => {
                if (task_item.item_id == item.id) {
                    task_item.item_name = item?.name

                    this.updateKeyForSourceAndDestination(task_item)
                }

            })))
        },
        updateKeyForSourceAndDestination(source, destination) {
            this.updateNodeKey(destination)
            if (source?.id != destination?.id)
                this.updateNodeKey(source)
        },
        updateNodeKey(object) {
            if (!object)
                return

            object.key = Date.now() + (object.id ?? 10)
        },
        removeItem(item_id) {
            this.team_panel_data
                ?.find(profile => {
                    profile?.data?.find(item_group => {
                        const group_item_index = item_group?.items?.findIndex(group_item => group_item.item_id == item_id)
                        if (group_item_index >= 0)
                            item_group?.items?.splice(group_item_index, 1)
                    })
                })
        },
        onSelectSubordinate(subordinate, assigner) {

            if (assigner.user_profile_id === this.getCurrentUserProfileId()
                && subordinate.user_profile_id !== assigner.user_profile_id) {
                const profile = this.byUserProfileTask(subordinate.user_profile_id);
                this.setSelectedTeamMember(profile);
                return;
            }

            this.setSelectedTeamMember(assigner, subordinate);
        },
        onDrillDown(subordinate) {
            this.setSelectedTeamMemberById(subordinate.user_profile_id);
            this.new_current_user = subordinate.user_profile_id;
            this.team_panel_subordinates = true;
            this.opened_member = subordinate;
            this.reorderTeamPanel();
        },
        filterDataOnLiveSync() {
            this.setSelectedMemberTasks();
        },
        hasProfileActiveTask(assignee_id, assigner_id) {
            return Boolean(this.team_panel_data.find(profile =>
                profile.user_profile_id === assignee_id)?.data?.filter(group =>
                group?.items?.filter(task => task?.item?.version?.user_profile_id_creator === assigner_id)?.length > 0
            )?.length);
        },
        onPreviousAssigner() {
            this.setPinnedTeamMember(null)
            this.toggleSubordinates(false)
            this.setPinnedTeamMember()
            this.onEmptySelectedMemberList()
            this.reorderTeamPanel();
        },

        setHasTeamActiveItem(val) {
            this.has_team_active_item = val
        },

        async endAssignment(item, ctr = 0) {
            const {data} = await axios.get(`/user-profile-tasks/items/${item.id}/end-assignment`);
            this.removeItem(item.id)

            const {toggleSnackBar} = Utilities()
            timeOutHelper(() => {
                toggleSnackBar({
                    title: item.name,
                    message: `Item assignment ended.`,
                    timeout: 3000,
                    color: 'success',
                    location: 'bottom right'
                })
            }, 3000 * ctr)
        },

        async loadScreenshots(profile_id, selected_duration) {

            const response = await axios.post("/user-profile-tasks/screenshots", {
                profile_id: profile_id,
                duration: selected_duration,
                timezone: DateUtilities.getTimezone(),
            });

            this.screenshots = response.data.data;

            return this.screenshots
        },
        async getShiftOverview(member_id) {
            const user_store = useUserStore()
            let shift_overview = []

            try {
                const response = await axios.post("/reports/shifts", {
                    user_profile_ids: [member_id],
                    by: "day",
                    timezone: DateUtilities.getTimezone(),
                    date: DateUtilities.getStartAndEndDateFromToday(-7)
                });
                shift_overview = response.data?.data;
            } catch (error) {
                console.log({error})
            }

            return shift_overview
        },
        isActiveItemForProfile(profile_id, item_id) {
            return this.getProfileActiveTask(profile_id)?.id === item_id
        },
        isPausedItemForProfile(profile_id, item_id) {
            return this.getProfilePausedTask(profile_id)?.id === item_id
        },
        setTeamPanelDatePrepared(value) {
            this.team_panel_data_prepared = value;
        },
        updateActiveUserTaskByTaskId(task_id, data) {
            this.selected_member?.tasks?.data.forEach(group => {
                group?.items?.forEach(task => {
                    if (task.item_id === task_id) {
                        task.item.name = data.name
                        task.item.slug = data.slug
                    }
                })
            })
        },
        isSelfAssigned(assigner_id, item){
            return assigner_id == item.version?.user_profile_id_assignee;
        }
    }
})
