import {defineStore} from "pinia";
import {useUserStore} from "../../../../Stores/User";

export const useTeam = defineStore('TeamStore', {
    state: () => ({
        team_list: [],
        assigned: null,
        currently_assigned: null,
        assigned_check_btn: false,
        create_variant: false,
        toggle_create: true,
        new_variant_name: null,
        are_users_invited: false,
        group_image: null
    }),
    getters: {
        // is_comment_tab: (state) => state.tab === 'comment'

        get_checked_invitees: (state) => {
            let members = state.team_list.filter(member => {
                if (member.checked === true) {

                    if (!member.checkedOrder) {
                        member.checkedOrder = Date.now()
                    }
                    return true
                }
            })

            const current_profile_id = useUserStore().getCurrentUserProfileId();
            const current_user = members.find(member => member.id == current_profile_id)
            if(current_user){
                current_user.checkedOrder = 1;
            }

            return members.sort(function (a, b) {
                return a.checkedOrder - b.checkedOrder
            })
        }
    },
    actions: {
        setAssigned(val) {
            console.log('setAssigned', val)
            this.assigned = val
        },
        setOriginalAssigned(val) {
            this.currently_assigned = val
        },

        resetAssigned() {
            this.setAssigned(null)
        },

        setAssignedCheckBtn(val) {
            this.assigned_check_btn = val
        },

        setCreateVariant(val) {
            this.create_variant = val
        },

        setToggleCreate(val) {
            this.toggle_create = val
        },

        setNewVariantName(val) {
            this.new_variant_name = val
        },

        resetNewVariantName() {
            this.setNewVariantName(null)
        },

        setAreUsersInvited(val) {
            this.are_users_invited = val
        },

        setTeamList(val) {
            this.team_list = val
        },

        assignedTo(member) {

            if (!this.assigned || this.assigned != member?.id) {
                this.setAssigned(member?.id)
                member.checked = true
                if (member.is_login == 0 && !member.checkedOrder) {
                    member.checkedOrder = Date.now()
                }
            } else {
                this.setAssignedCheckBtn(false)
                this.resetAssigned()
            }
        },

        checkGroupItem(groups, members) {
            groups.getOtherParticipants().forEach(group_member => {
                const user = members.find(invUser => invUser.id == group_member.id)

                if (!user)
                    return

                groups.checked ? (user.checked ? user.count += 1 : user.count = 1) : user.count -= 1
                user.count > 0 ? user.checked = true : user.checked = false
            })
        },

        uncheckGroupsWhenMemberisUnchecked(groups) {
            groups.forEach(item => {
                if (item.checked) {
                    item.participants.forEach(participant => {
                        if (!this.get_checked_invitees.find(checked => checked.id === participant.id)) {
                            item.checked = false
                            return
                        }
                    })
                }
            })
        }
    }
})
