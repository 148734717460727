import { useConversation } from "../Stores/Conversation";
export function getChinUsers({ profiles, all_profile_ids, selected_profile_ids }) {
	return all_profile_ids
	.map((profile_id) => profiles.find(profile => profile.id == profile_id))
	.filter(profile => profile)
	.map(({ id, full_name, avatar_url }) => ({
			id,
			name: full_name,
			disabled: !selected_profile_ids.includes(id) ,
			imageSrc: avatar_url,
	}))
}

export async function handleInviteApi({ selected_entities, get_api_url, update_store }, is_conversation = false) {
	let profile_ids = []
	let selected_ids = []
	try {
        const responses = await Promise.all(
            selected_entities
                .filter((entity) => entity)
                .map((entity) => axios.get(get_api_url(entity)))
        );

        profile_ids = responses
            .flatMap(response => [
                ...response.data.all_profile_ids
            ]).filter((e, i, a) => a.indexOf(e) === i);

        selected_ids = responses
            .flatMap(response => [
                ...response.data.invited_profile_ids
            ]);


        if(is_conversation) {
            selected_entities.forEach((entity) => {
                const conv = useConversation().getConversationByItemId(entity.id)
                if (conv)
                    selected_ids = [...selected_ids, ...conv.getActiveParticipats().map(item => item.id)]
            })
        }
		selected_ids = selected_ids.filter((item, pos) => selected_ids.indexOf(item) === pos)

	} catch(error) {
		console.log(`Error - invite api`, error)
	}

	update_store(selected_ids, profile_ids)
}
