const intlWeekDayFormatter = new Intl.DateTimeFormat('en-GB', {weekday: 'long'})
//const intlDateTimeFormatter = new Intl.DateTimeFormat('en-GB', { month: 'long', year: 'numeric'})
const intlDateTimeFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'long',
    day: "numeric",
    year: 'numeric'
})
export default {

    secsBetween: function (date1, date2) {
        const datediff = Math.abs(date1.getTime() - date2.getTime()); // difference
        return parseInt(datediff / 1000, 10);
    },

    minutesBetween: (date1, date2) => {
        const datediff = Math.abs(date1.getTime() - date2.getTime()); // difference
        return parseInt(datediff / (1000 * 60), 10);
    },

    hrsBetween: function (date1, date2) {
        var datediff = Math.abs(date1.getTime() - date2.getTime()); // difference
        return parseInt(datediff / (1000 * 60 * 60), 10);
    },

    isPast: function (date) {
        //console.log( "date .. before ", date )
        if (typeof date === 'string')
            date = new Date(date);
        return new Date > date;
        //console.log("date .. after ", date);
        const datediff = new Date - date; //difference
        //console.log("diff --", datediff);
        return datediff < 0 ? false : true
    },

    daysBetween: function (date1, date2) {
        date1.setHours(0);
        date1.setMinutes(0, 0, 0);
        date2.setHours(0);
        date2.setMinutes(0, 0, 0);
        var datediff = Math.abs(date1.getTime() - date2.getTime()); // difference
        return parseInt(datediff / (24 * 60 * 60 * 1000), 10); //Convert values days and return value
    },

    weeksBetween: function (d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    monthsBetween: function (d1, d2) {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
    },

    yearsBetween: function (d1, d2) {
        return d2.getFullYear() - d1.getFullYear();
    },

    formatDate: function (date) {
        const today = new Date;
        const dayDiffWithToday = this.daysBetween(today, date)

        if (dayDiffWithToday == 0)
            return "Today";

        if (dayDiffWithToday < 7)
            return intlWeekDayFormatter.format(date);

        return `${intlDateTimeFormatter.format(date)}`;
    },

    asDate: (date) => {
        if (typeof date == 'string')
            date = new Date(date)
        return new Intl.DateTimeFormat('en-US').format(date)
    },

    asDateWithTime: (date) => {
        if (typeof date == 'string')
            date = new Date(date)
        // sometimes even the US needs 24-hour time
        const options = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false,
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);

    },

    forDateField: (date) => {
        return new Date(date).toISOString().split('T')[0];
    },

    timeFormat: (stamp, is12Hours = true) => {
        const d = new Date(stamp)
        return d.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: is12Hours})
    },
    timeSince: (date) => {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    },

    getTimezone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    },

    getDisplayTimeFromTimestamp(timestamp) {
        if (timestamp == null) return ""

        return new Date(timestamp).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    },

    getTimeFromTimestamp(timestamp) {
        if (timestamp == null) return ""

        return new Date(timestamp).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit"
        });
    },

    isValidTime(timeString) {
        // Regular expression for the time format "hh:mm AM/PM"
        const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] [APap][Mm]$/;

        if (!timeRegex.test(timeString)) {
            // If the format is not valid
            return false;
        }

        // Splitting the time string into hours, minutes, and AM/PM parts
        const [hours, minutes, ampm] = timeString.split(/:|\s/);

        // Converting hours to a 24-hour format
        let hours24 = parseInt(hours, 10);

        // Adjusting for AM/PM
        if (ampm.toLowerCase() === 'pm' && hours24 !== 12) {
            hours24 += 12;
        } else if (ampm.toLowerCase() === 'am' && hours24 === 12) {
            hours24 = 0;
        }

        // Checking if hours and minutes are valid
        return hours24 >= 0 && hours24 < 24 && parseInt(minutes, 10) >= 0 && parseInt(minutes, 10) < 60;
    },

    toHoursAndMinutes(input_minutes) {
        if (input_minutes == 0) return "00:00";

        const hours = Math.floor(input_minutes / 60);
        const minutes = Math.floor(input_minutes % 60);
        const pad = (number) => number.toString().padStart(2, "0");

        return `${pad(hours)}:${pad(minutes)}`;
    },

    getStartAndEndDateFromToday(difference) {
        const format = d => d.toISOString().split("T")[0]
        const date = new Date()
        date.setDate(date.getDate() + difference)

        return {
            start_date: format(date),
            end_date: format(new Date()),
        }
    },

    sortByDateDesc(array, key = 'date') {
        return array.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a[key]) - new Date(b[key]);
        });
    },

    getDisplayDuration(start_time, end_time) {
        const format = timestamp => new Date(timestamp).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        }).toLowerCase().replace(/\s/g, '')

        return start_time && end_time ? `${format(start_time)} - ${format(end_time)}` : format(start_time);
    },

    getAMPMtoDate(date_string){
        const parse_date = new Date(date_string);
        if(parse_date == 'Invalid Date'){
            return false;
        }

        return `${parse_date.getFullYear().pad()}-${(parse_date.getMonth() + 1).pad()}-${parse_date.getDate().pad()} ${parse_date.getHours().pad()}:${parse_date.getMinutes().pad()}:${parse_date.getSeconds().pad()}`;
    },

    getFileDownloadDateFormat(date) {
        return new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(date) +
            '.' +
            new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(date) +
            '.' +
            new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(date);
    },

    getDifferenceInDays: function (date1, date2) {
        // To calculate the time difference of two dates
        var difference_in_time = date2?.getTime() - date1?.getTime();
      
        // To calculate the no. of days between two dates
        return parseInt(difference_in_time / (1000 * 3600 * 24));
    }
}
