import { ref, computed } from 'vue'
import ConversationApi from '../composition-api/conversations';

const { makeCurrentConversationAsActive,
    makeCurrentConversationAsInActive,
    cacheConversationDrafts,
    } = ConversationApi();

const INACTIVE_USER_TIME_THRESHOLD = 4000;
const USER_ACTIVITY_THROTTLER_TIME = 1000;

const userActivityTimeout = ref(null);
const userActivityThrottlerTimeout = ref(null);
const isInactive = ref(true);

(function() {
    function activateActivityTracker() {
        window.addEventListener("mousemove", userActivityThrottler);
        window.addEventListener("scroll", userActivityThrottler);
        window.addEventListener("keydown", userActivityThrottler);
        //window.addEventListener("resize", userActivityThrottler);
        window.addEventListener("beforeunload", deactivateActivityTracker);
    }

    function deactivateActivityTracker(event) {
        cacheConversationDrafts()
        //event.returnValue = "wanrn user of leaving this page";
        window.removeEventListener("mousemove", userActivityThrottler);
        window.removeEventListener("scroll", userActivityThrottler);
        window.removeEventListener("keydown", userActivityThrottler);
        //window.removeEventListener("resize", userActivityThrottler);
        window.removeEventListener("beforeunload", deactivateActivityTracker);

        clearTimeout(userActivityTimeout.value);
        clearTimeout(userActivityThrottlerTimeout.value)
        userActivityTimeout.value =  userActivityThrottlerTimeout.value = null;
    }

    function resetUserActivityTimeout() {
        clearTimeout(userActivityTimeout.value);

        userActivityTimeout.value = setTimeout(() => {
            //userActivityThrottler();
            inactiveUserAction();
            clearTimeout(userActivityTimeout.value)
            userActivityTimeout.value = null;
        }, INACTIVE_USER_TIME_THRESHOLD);  // 25 seconds
    }

    function userActivityThrottler() {
        if (isInactive.value) {
            isInactive.value = false;
            //window.logTimeWith("user is ACTIVE")
            makeCurrentConversationAsActive();
        }

        if (!userActivityThrottlerTimeout.value) {
            userActivityThrottlerTimeout.value = setTimeout(() => {
                resetUserActivityTimeout();
                clearTimeout(userActivityThrottlerTimeout.value);
                userActivityThrottlerTimeout.value = null
            }, USER_ACTIVITY_THROTTLER_TIME); // 1 second
        }
    }

    function inactiveUserAction() {
        isInactive.value = true;
        //window.logTimeWith("user is INACTIVE")
        makeCurrentConversationAsInActive()
    }

    activateActivityTracker();
})();


export default function trackUserInactive() {
    const is_inactive = computed(() => isInactive.value);

    return {
        is_inactive
    }
}
