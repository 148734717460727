import axios from 'axios'
import {defineStore} from 'pinia'
import {useConversation} from './Conversation'
import {useWorkspace} from "./Workspaces";
import {useTeam} from "../Components/Custom/Team/Store/Team";
import Utilities from "../Helpers/utilities";

export const useInvitation = defineStore('InvitationStore', {
    state: () => ({
        user_profile_ids: [],
        selected_user_profile_ids: [],
        // selected_group_ids: [],
        selected_group_user_profile_ids: [],
        is_retrieving_invited_user_in_progress: false
    }),
    getters: {},
    actions: {
        resetInviteProfiles() {
            const all_profile_ids = this.selected_user_profile_ids.concat([...new Set(this.selected_group_user_profile_ids)])
            this.user_profile_ids = [...new Set(all_profile_ids)]
        },
        restoreSelectedInviteProfiles() {
            this.selected_user_profile_ids = this.user_profile_ids
        },
        clearSelectedInviteProfiles() {
            this.user_profile_ids = []
            this.selected_user_profile_ids = []
            this.selected_group_user_profile_ids = []
        },
        setProfileIds(val) {
            this.user_profile_ids = val
        },
        async getInvitedMembers(conversation) {
            const {space, board, item} = await useWorkspace().getOrLoadSpaceAndBoardForItemId(conversation?.type_id)
            try {
                return await axios.get(useWorkspace().prepareUrlForItemConversationInvitation(item, space, board));
            } catch (e) {
                console.log('getting invitation profile ids')
            }
        },
        async invitedProfiles() {
            this.is_retrieving_invited_user_in_progress = true;

            //reset previous profile ids if any
            this.setProfileIds([])
            useTeam().setAssigned(null)
            useTeam().setOriginalAssigned(null)

            let invited_profile_ids = []
            let assigned_profile_id = null
            const conversation = useConversation().active

            if (conversation?.is_item) {
                try {
                    const {data} = await this.getInvitedMembers(conversation)
                    invited_profile_ids = data?.invited_profile_ids
                    assigned_profile_id = data?.assigned_profile_id
                } catch (e) {
                    Utilities().showErrorSnackbar('Failed to retrieve existing invitation');
                    console.trace(e)
                    return;
                }
            }

            if (conversation.is_group || conversation.is_group_one_off) {
                invited_profile_ids = conversation.getActiveParticipantIds()
            }

            if (!invited_profile_ids) {
                invited_profile_ids = []
            }

            //invited_profile_ids.push(useWorkspace().getSpaceOwnerId())
            invited_profile_ids = Array.from(new Set(invited_profile_ids))

            this.setProfileIds(invited_profile_ids)
            useTeam().setAssigned(assigned_profile_id)
            useTeam().setOriginalAssigned(assigned_profile_id)

            this.restoreSelectedInviteProfiles()
            this.is_retrieving_invited_user_in_progress = false;
        }
    }
})
