import { defineStore } from "pinia";
import { useUserStore } from '../../../Stores/User'
import { useConversation } from '../../../Stores/Conversation'
import { useAppState } from '../../../Stores/AppState'
import Utilities from '../../../Helpers/utilities'
import ArrayUtilities from '../../../Utilities/ArrayUtilities'

const REQUEST_TYPES = {
    SUPPORT: 'support',
    STATUS: 'request_status',
    ATTENTION: 'attention'
}

export const useRequest = defineStore('Request', {
    state: () => ({
        support: {icon: 'support_agent', message: 'Support requested from ', closed_message: 'Support Request Closed', message_to: 'Support requested to '},
        status: {icon: 'question_mark', message: 'Status requested from ', closed_message: 'Status Request Closed', message_to: 'Status requested to '},
        attention: {icon: 'priority_high', message: 'Attention requested from ', closed_message: 'Attention Request Closed', message_to: 'Attention requested'},
        urgent_request: null,
        urgent_attention_request_user_id: null,
        request_sent: [],
        urgent_request_with_timestamp: null,
        attention_request_snackbar: false,
        request_to_bounce: null,
        is_bounce: false
    }),
    getters:{},
    actions: {
        setAttentionRequestSnackbar(val) {
            this.attention_request_snackbar = val
        },

        setUrgentRequest(val) {
            this.urgent_request = val
        },

        setUrgentRequestWithTimestamp(val) {
            this.urgent_request_with_timestamp = val
        },

        closeRequest(conversation, id) {
            const urgent_request = conversation.state.urgent_request

            let index = this.hasRequest(id, urgent_request)
            urgent_request.splice(index, 1)

            conversation.updateAttributes(urgent_request)

            index = this.hasRequest(id)
            this.request_sent.splice(index, 1)
        },

        closeAttentionRequest(conversation, users, current_user_id, update_attribute = true) {
            let urgent_request = conversation.state.urgent_request ?? []

            users.forEach(user => {
                urgent_request = this.removeRequest(urgent_request, `${current_user_id}-${conversation?.id}-attention-${user.twilio_identity}`)
            });

            conversation.sendUrgentMessages(users, 'attention_closed', 'Attention Request Closed')

            if (update_attribute)
                conversation.updateAttributes(urgent_request)
        },

        hasRequestWithTimestamp(id, timestamp, custom_data = null) {
            return (custom_data || this.request_sent).findIndex( req => req.id === id && req.timestamp === timestamp)
        },

        hasRequest(id, custom_data = null) {
            return (custom_data || this.request_sent).findIndex( req => req.id === id)
        },

        getRequestById(id) {
            return this.request_sent.find( req => req.id === id)
        },

        isUserInAttentionRequest(user_id) {
            const index = this.hasRequest(id)
            if (index >= 0)
                return this.request_sent[index].user.findIndex( req => req.id === user_id)
        },


        userGotAttentionRequest(id, conv_id, request) {
            const attention = (request || this.request_sent)?.find(req => this.isAttention(req.type) && req.user.twilio_identity === id && req.conv_id === conv_id)
            
            return attention
        },

        removeRequest(request, id) {
            let index = this.request_sent.findIndex( req => req.id === id)
            this.request_sent.splice(index, 1)
            
            index = request.findIndex( req => req.id === id)
            request.splice(index, 1)
            
            return request
        },

        sendRequest(conversation, user, type, skip = false) {
            const timestamp = Date.now()
            const id = `${useUserStore().getTwilioIdentity}-${conversation.id}-${type}-${user.twilio_identity}`
            conversation.updateAttributes(this.setRequest(
                conversation.state.urgent_request, 
                user, 
                conversation.id, 
                type, 
                skip, 
                id,
                timestamp
            ))
            
            return {id: id, timestamp: timestamp}
        },

        setRequest(request, user, conv_id, type, skip = false, id, timestamp) {
            if (request.find( req => req.user.twilio_identity === user.twilio_identity && req.type === type && useUserStore().isCurrentUser(req?.from?.twilio_identity)) && !skip)
                return request

            const request_type = this.getRequestType(type)
            const new_timestamp = timestamp ?? Date.now()
            const new_id = id ?? `${useUserStore().getTwilioIdentity}-${conv_id}-${type}-${user.twilio_identity}`

            const data = {
                id: new_id,
                from: ArrayUtilities.clone(useUserStore().profile),
                user: user, 
                type: type, 
                icon: request_type.icon, 
                message: request_type.message + useUserStore().user.first_name, 
                message_to: request_type.message_to + (type !== 'attention' ? user.name: ''), 
                closed_message: request_type.closed_message, 
                conv_id: conv_id,
                timestamp: new_timestamp
            }
            
            this.request_sent.push(data)
            request.push(data) 
            return request
        },

        getRequestType(type) {
            switch (type) {
                case 'support':
                    return this.support
            
                case 'request_status':
                    return this.status
            
                default:
                    return this.attention
            }
        },

        isSentRequest(user, type, request) {
            if ((request || this.request_sent).find( req => 
                req.user.twilio_identity === user.twilio_identity && 
                req.type === type))
                return true
            
            return false
        },

        openChat(conv_id) {
            const { toggleChatPopover }  = Utilities()
            const app_state_store = useAppState()
            const conversation_store = useConversation()

            conversation_store.openConversationById(conv_id)
            const conversation = conversation_store.getConversationById(conv_id)
            
            toggleChatPopover(true)
            app_state_store.toggleInviteDrawer(false, conversation.originalData.item)
        },

        setAttentionUserId(id) {
            this.urgent_attention_request_user_id = id
        },
        

        updateUrgentRequest(requests = [], conv_id = null) {
            if (conv_id >= 0) 
                this.request_sent = this.request_sent.filter(req => req.conv_id != conv_id)

            requests.forEach((req) => {
                if (useUserStore().isCurrentUser(req?.from?.twilio_identity) || 
                    useUserStore().isCurrentUser(req?.user?.twilio_identity)) {
                    const index = this.request_sent.findIndex(req_sent => req_sent.id === req.id)
    
                    if (index < 0)
                        this.request_sent.push(req)
                }
            })
        },

        // confirmedRequest(attention_request) {
        //     attention_request.forEach(ar => {
        //         const conversation = useConversation().getConversationById(ar.conv_id)
        //         const urgent_request = conversation.state.urgent_request
        //         urgent_request.forEach(req => req.id === ar.id && (req.confirmed = true))
        //         conversation.updateAttributes(urgent_request)
        //     })
        // },

        setRequestToBounce(val) {
            this.request_to_bounce = val
        },

        setIsBounce(val) {
            this.is_bounce = val
        },

        isAttention(request) {
            return request === REQUEST_TYPES.ATTENTION
        },

        isSupport(request) {
            return request === REQUEST_TYPES.SUPPORT
        },

        isStatus(request) {
            return request === REQUEST_TYPES.STATUS
        }
    }
})
