
// export const DEFAULT_MODULE = { title: 'Team', name: 'team', icon: 'mdi-account-group-outline' }
// export const DEFAULT_MODULE = { title: 'Activity', name: 'activity', icon: 'mdi-home-city' }
// export const DEFAULT_MODULE = { title: 'User Settings', name: 'user-settings', icon: 'mdi-account-settings-outline' }

import Cache from "../Utilities/Cache";

export const MAIN_MODULES = [
    { title: 'Store', name: 'store', icon: 'mdi-home-city' },
    { title: 'Work', name: 'work', icon: 'mdi-account' },
    { title: 'Activity', name: 'activity', icon: 'mdi-home-city' },
    { title: 'Team', name: 'team', icon: 'mdi-account-group-outline' },
    { title: 'Goals', name: 'goals', icon: 'mdi-home-city' },
    { title: 'Planner', name: 'planner', icon: 'mdi-home-city' },
    { title: 'User Settings', name: 'user-settings', icon: 'mdi-account-settings-outline' },
]

const cache = new Cache()

export const getModuleByName = (name)=> MAIN_MODULES.find(menu=>menu.name == name)

export const DEFAULT_MODULE = getModuleByName(cache.get('active_page')) ?? MAIN_MODULES[1]
export const DEFAULT_PAGE = cache.get('active_page') ?? null

export const ACTIVITY_TABS = [
    {
        name: 'dm',
        text: 'DM',
    },
    {
        name: 'task',
        text: 'Task',
    },
    {
        name: 'board',
        text: 'Board',
    },
    {
        name: 'groups',
        text: 'Groups',
    }
]

export const USER_SETTING_OPTIONS = {
    account: {
        name: 'account',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    },
    profile: {
        name: 'profile',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    },
    identity: {
        name: 'identity',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    },
    social: {
        name: 'social',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    },
    organizations: {
        name: 'organizations',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    },
    billing: {
        name: 'billing',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    },
    security: {
        name: 'security',
        description: 'With Kaamfu you can leave a profile for every role you occupy. This allows you easily segragate your activities share your work with the people that matter. Everyone signs up with a Personal profile, and then you can add additional personal and professional profiles as you need.'
    }
}

export const NEW_SPACE = [
    { title: 'Space', label: 'Common', ref: 'space', expanded: false, icon: 'sort' },
    { title: 'Website Feedback', label: 'Website Feedback', ref: 'feedback', expanded: true, icon: 'rate_review' },
    { title: 'Invoice', label: 'Invoice', ref: 'invoice', expanded: true, icon: 'receipt_long' },
    { title: 'Shortcut', label: 'Shortcut', ref: 'shortcut', expanded: true, icon: 'link' },
    { title: 'Folder', label: 'Folder', ref: 'folder', expanded: false, icon: 'folder' },
    { title: 'File', label: 'File', ref: 'file', expanded: false, icon: 'description' },
    { title: 'Process', label: 'Process', ref: 'process', expanded: true, icon: 'manage_history' },
    { title: 'Account', label: 'Account', ref: 'account', expanded: false, icon: 'request_quote' },
    { title: 'Landing Page', label: 'Landing Page', ref: 'landing_page', expanded: false, icon: 'web' },
    { title: 'Form', label: 'Form', ref: 'form', expanded: false, icon: 'article' },
    { title: 'Survey', label: 'Survey', ref: 'survey', expanded: true, icon: 'checklist' },
    { title: 'Customer', label: 'Customer', ref: 'customer', expanded: true, icon: 'groups' },
    { title: 'Proposal', label: 'Proposal', ref: 'proposal', expanded: true, icon: 'note_alt' },
    { title: 'Estimate', label: 'Estimate', ref: 'estimate', expanded: false, icon: 'calculate' },
    { title: 'Project', label: 'Project', ref: 'project', expanded: true, icon: 'edit_calendar' },
    { title: 'Job Post', label: 'Job Post', ref: 'job_post', expanded: false, icon: 'business_center' },
    { title: 'Payment', label: 'Payment', ref: 'payment', expanded: true, icon: 'payments' },
    { title: 'Schedule', label: 'Schedule', ref: 'schedule', expanded: false, icon: 'work_history' },
    { title: 'Contract', label: 'Contract', ref: 'contract', expanded: false, icon: 'handshake' },
    { title: 'Workflow', label: 'Workflow', ref: 'workflow', expanded: false, icon: 'account_tree' }
]

export const MAIN_ACCOUNT_MENU = [
    { text: 'Add Another Account', value: 'add', icon: 'mdi-account-plus-outline', variant: 'menu_item' },
    { text: 'Divider', value: '', icon: '', variant: 'divider' },
    { text: 'Settings', value: 'settings', icon: 'mdi-account-cog-outline', variant: 'menu_item' },
    { text: 'Billing', value: 'billing', icon: 'mdi-receipt-text-outline', variant: 'menu_item' },
    { text: 'Help', value: 'help', icon: 'mdi-help-circle-outline', variant: 'menu_item' },
    { text: 'Divider', value: '', icon: '', variant: 'divider' },
    { text: 'Logout', value: 'logout', icon: 'mdi-logout-variant', variant: 'menu_item' },
]

export const WORKSPACE_HOVER_MENU_OPTIONS_GROUP = [
    { text: 'Get Link', value: 'get_link', icon: 'mdi-link', variant: 'menu_item' },
    { text: 'Delete', value: 'delete', icon: 'mdi-delete-outline', variant: 'menu_item' },
    { text: 'Archive', value: 'archive', icon: 'mdi-archive-outline', variant: 'menu_item' },
    { text: 'Copy', value: 'copy', icon: 'mdi-content-copy', variant: 'menu_item' }
]

export const CHAT_OPTIONS_MENU = [
    { value: 'item_one', title: 'Select One' },
    { value: 'item_two', title: 'Select Two' },
    { value: 'item_three', title: 'Select Three' }
]

export const CHAT_HOVER_MENU_ITEMS = [
    { value: 'reply-to', text: 'Reply To', icon: 'reply' },
    { value: 'forward', text: 'Forward', icon: 'forward' },
    { value: 'copy', text: 'Copy', icon: 'content_copy' },
    { value: 'options-menu', text: 'Options', icon: 'more_horiz' }
    // { value: 'select', text: 'Select', icon: 'mdi-checkbox-blank-circle-outline' }
]

export const STORE_SERVICE_OPTIONS = [
    { value: 'option_one', text: 'Option One' },
    { value: 'option_two', text: 'Option Two' },
    { value: 'option_three', text: 'Option Three' },
    { value: 'option_four', text: 'Option Four' },
]
export const STORE_SELLER_DETAILS = [
    { value: 'seller_one', text: 'Seller One' },
    { value: 'seller_two', text: 'Seller Two' },
    { value: 'seller_three', text: 'Seller Three' },
    { value: 'seller_four', text: 'Seller Four' },
]
export const STORE_BUDGET = [
    { value: 'budget_one', text: '100.00' },
    { value: 'budget_two', text: '200.00' },
    { value: 'budget_three', text: '300.00' },
    { value: 'budget_four', text: '400.00' },
]
export const STORE_DELIVERY_TIME = [
    { value: 'delivery_one', text: '1hr' },
    { value: 'delivery_two', text: '2hrs' },
    { value: 'delivery_three', text: '4hrs' },
    { value: 'delivery_four', text: '8hrs' },
]
export const STORE_PROVIDERS = [
    { value: 'provider_one', text: 'Prospus' },
    { value: 'provider_two', text: 'Kaamfu' },
    { value: 'provider_three', text: 'Google' },
    { value: 'provider_four', text: 'Facebook' },
]

export const RIGHT_DRAWER_MINI_TOOLBAR = [
    {
        label: 'Tool 1', icon: 'mdi-resize'
    },
    {
        label: 'Tool 2', icon: 'mdi-application-array-outline'
    },
    {
        label: 'Tool 4', icon: 'mdi-application-outline'
    },
    {
        label: 'Tool 3', icon: 'mdi-application-brackets-outline'
    }
]

export const WORKSPACE_TEAM_MENU = [
    {
        label: 'Profile', name: 'profile', icon: 'mdi-card-account-details-outline', type: 'button', variant: 'text',
    },
    {
        label: 'Chat', name: 'chat', icon: 'mdi-message-text-outline', type: 'button', variant: 'text',
    },
    {
        label: 'Options', name: 'options', icon: 'mdi-menu', type: 'button', variant: 'text',
    },
    // {
    //     label: 'Pinned', name: 'pinned', icon: 'mdi-pin-outline', type: 'button', variant: 'text',
    // },
]

export const WORKSPACE_HOVER_MENU = {
    tasks: [
        {
            label: 'Chat', name: 'chat', icon: 'mdi-message-text', type: 'button', variant: 'text',
        },
        {
            label: 'Add', name: 'add', icon: 'mdi-plus', type: 'button', variant: 'text',
        },
        // {
        //     label: 'Delete', name: 'delete', icon: 'mdi-close', type: 'button', variant: 'text',
        // },
        // {
        //     label: 'Options', name: 'options', icon: 'mdi-menu', type: 'menu', variant: 'text',
        // },
    ],
    taskitems: {
        spaces: [
            {
                label: 'Delete', name: 'delete', icon: 'mdi-close-circle-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Divider', name: '', icon: '', type: 'divider', variant: 'divider',
            },
            {
                label: 'Clipboard', name: 'clipboard', icon: 'mdi-clipboard-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Chat', name: 'chat', icon: 'mdi-message-reply-text-outline', type: 'button', variant: 'text',
            },
            // {
            //     label: 'Options', name: 'options', icon: 'mdi-menu', type: 'menu', variant: 'text',
            // },
        ],
        boards: [
            {
                label: 'Delete', name: 'delete', icon: 'mdi-close-circle-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Divider', name: '', icon: '', type: 'divider', variant: 'divider',
            },
            {
                label: 'Clipboard', name: 'clipboard', icon: 'mdi-clipboard-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Chat', name: 'chat', icon: 'mdi-message-reply-text-outline', type: 'button', variant: 'text',
            },
            // {
            //     label: 'Options', name: 'options', icon: 'mdi-menu', type: 'menu', variant: 'text',
            // },
        ],
        items: [
            // { label: 'Edit', name: 'edit', icon: 'mdi-pencil', type: 'button', variant: 'text' },
            {
                label: 'Delete', name: 'delete', icon: 'mdi-close-circle-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Divider', name: '', icon: '', type: 'divider', variant: 'divider',
            },
            {
                label: 'Clipboard', name: 'clipboard', icon: 'mdi-clipboard-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Document', name: 'document', icon: 'mdi-note-text-outline', type: 'button', variant: 'text',
            },
            {
                label: 'Chat', name: 'chat', icon: 'mdi-message-reply-text-outline', type: 'button', variant: 'text',
            },
            // {
            //     label: 'Options', name: 'options', icon: 'mdi-menu', type: 'menu', variant: 'text',
            // },
        ],
    },
}
